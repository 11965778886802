/* Images Kit Antihumidity */
import kit from "../../../assets/images/antihumidity/kit/kit.jpg";
import lisa from "../../../assets/images/antihumidity/kit/lisa.jpg";
import arena from "../../../assets/images/antihumidity/kit/arena.jpg";
import piedra from "../../../assets/images/antihumidity/kit/piedra.jpg";

const itemsKit = [
    {
        image: kit,
        alt: 'Kit Antihumedad Tauroc', 
        price: '$ - ', //3968 (contado)
        title: 'Kit Antihumedad Tauroc',
        isOffer: true,
        items: [
            {
                value: 'Incluye',
                description: '8 placas antihumedad y cubre 1,44m²' 
            },
        ]
    },
    {
        image: lisa,
        alt: 'Modelo Lisa', 
        title: 'Lisa',        
        price: '$ - ', //589 (contado) la unidad llevando el Kit 
        items: [
            {
                value: 'Medida',
                description: '0,60 x 0,30 mts' 
            },
        ],
        isKit: true,
    },
    {
        image: arena,
        alt: 'Modelo Arena', 
        title: 'Arena',
        price: '$ - ', //589 (contado) la unidad llevando el Kit
        items: [
            {
                value: 'Medida',
                description: '0,60 x 0,30 mts' 
            }
        ],
        isKit: true,
    },
    {
        image: piedra,
        alt: 'Modelo Piedra', 
        title: 'Piedra',
        price: '$ - ', //589 (contado) la unidad llevando el Kit
        items: [
            {
                value: 'Medida',
                description: '0,60 x 0,30 mts' 
            }
        ],
        isKit: true,
    },
];

export default itemsKit;