import Floors from './../../apps/components/Floors';
import FloorsFloating from './../../apps/components/Floors/Floating';
import FloorsVinyl from './../../apps/components/Floors/Vinyl';
import FloorsBaseboards from '../../apps/components/Floors/Baseboards';
import SyntheticGrass from './../../apps/components/Floors/SyntheticGrass';
import Deck from '../../apps/components/Floors/Deck';
import FloorsOffers from '../../apps/components/Floors/Offers';

const FloorsRoute = {
  path: '/pisos',
  exact: 'true',
  component: Floors,
};

export const FloorsFloatingRoute = {
  path: '/pisos/pisos-flotantes',
  exact: 'true',
  component: FloorsFloating,
};

export const FloorsVinylRoute = {
  path: '/pisos/pisos-a-prueba-de-agua',
  exact: 'true',
  component: FloorsVinyl,
};

export const FloorsOffersRoute = {
  path: '/pisos/ofertas',
  exact: 'true',
  component: FloorsOffers,
};

export const FloorsBaseboardsRoute = {
  path: '/pisos/zocalos-y-accesorios',
  exact: 'true',
  component: FloorsBaseboards,
};

export const SyntheticGrassRoute = {
  path: '/pisos/cesped-sintetico',
  exact: 'true',
  component: SyntheticGrass,
};

export const DeckRoute = {
  path: '/pisos/deck-vinilico',
  exact: 'true',
  component: Deck,
};

export default FloorsRoute;