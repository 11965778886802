import { faWhatsapp, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faEnvelope, faPhoneSquare } from "@fortawesome/free-solid-svg-icons";

const cards = [
  {
    title: "Visitanos",
    icons: [faMapMarkerAlt],
    subtitle: "Angel Brunel 874, Bahía Bca.",
    text: "Lunes a Viernes 9.30hs a 13hs y de 14hs a 17hs",
    buttons: [],
    color: 'red',
  },
  {
    title: "Escribinos",
    icons: [faWhatsapp, faEnvelope],
    subtitle: "Solicitá tu presupuesto sin cargo",
    text: "",
    buttons: [
      {
        textButton: "alcarbahia@yahoo.com.ar",
        linkButton:
          "mailto:alcarbahia@yahoo.com.ar?subject=ALCAR%20BAHIA%20CLIENTE",
      },
    ],
    color: 'lightblue',
  },
  {
    title: "Llamanos",
    icons: [faPhoneSquare],
    subtitle: "En horario de atención comercial",
    text: "(0291) 4526524 - 2914634258 - 2914758997",
    buttons: [],
    color: 'aquamarine',
  },  
  {
    title: "Seguinos",
    icons: [faFacebook, faInstagram],
    subtitle: "Seguí nuestras redes sociales",
    text: "",
    buttons: [
      {
        textButton: "Facebook",
        linkButton: "https://www.facebook.com/alcar.bahia",
      },
      {
        textButton: "Instagram",
        linkButton: "https://www.instagram.com/alcarbahia",
      },
    ],
    color: 'pink',
  },
];

export default cards;
