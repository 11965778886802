import Pvc from './../../apps/components/Pvc';
import PvcCeilings from './../../apps/components/Pvc/Ceilings';
import PvcCoatings from './../../apps/components/Pvc/Coatings';
import PvcCurtains from './../../apps/components/Pvc/Curtains';
import PvcDoors from './../../apps/components/Pvc/Doors';

const PvcRoute = {
  path: '/pvc',
  exact: 'true',
  component: Pvc,
};

export const PvcCeilingsRoute = {
  path: '/pvc/cielorrasos',
  exact: 'true',
  component: PvcCeilings,
};

export const PvcCoatingsRoute = {
  path: '/pvc/revestimientos',
  exact: 'true',
  component: PvcCoatings,
};

export const PvcCurtainsRoute = {
  path: '/pvc/cortinas',
  exact: 'true',
  component: PvcCurtains,
};

export const PvcDoorsRoute = {
  path: '/pvc/puertas-plegables',
  exact: 'true',
  component: PvcDoors,
};

export default PvcRoute;