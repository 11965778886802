import galeryGrass1 from "../../../../assets/images/floors/syntheticgrass/galery/galeryGrass1.jpg";
import galeryGrass2 from "../../../../assets/images/floors/syntheticgrass/galery/galeryGrass2.jpg";
import galeryGrass3 from "../../../../assets/images/floors/syntheticgrass/galery/galeryGrass3.jpg";
import galeryGrass4 from "../../../../assets/images/floors/syntheticgrass/galery/galeryGrass4.jpg";
import galeryGrass5 from "../../../../assets/images/floors/syntheticgrass/galery/galeryGrass5.jpg";
import galeryGrass6 from "../../../../assets/images/floors/syntheticgrass/galery/galeryGrass6.jpg";
import galeryGrass7 from "../../../../assets/images/floors/syntheticgrass/galery/galeryGrass7.jpg";
import galeryGrass8 from "../../../../assets/images/floors/syntheticgrass/galery/galeryGrass8.jpg";

const photos = [
    {
      src: galeryGrass1,
      width: 3,
      height: 3
    },
    {
      src: galeryGrass2,
      width: 1,
      height: 1
    },
    {
      src: galeryGrass3,
      width: 3,
      height: 4
    },
    {
      src: galeryGrass4,
      width: 4,
      height: 4
    },
    {
      src: galeryGrass5,
      width: 3,
      height: 4
    },
    {
      src: galeryGrass6,
      width: 4,
      height: 3
    },
    {
      src: galeryGrass7,
      width: 3,
      height: 3
    },
    {
      src: galeryGrass8,
      width: 4,
      height: 3
    },    
  ];

export default photos;