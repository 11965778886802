import React from "react";
import routes from "./routes";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "fontsource-roboto";

//own
import ProtectedRoute from "./ProtectedRoute";
import Header from "./containers/Header";
import Footer from "./containers/Footer";
import ScrollToTopWidget from "./packages/app-components/ScrollToTopWidget";
import WhatsAppWidget from "./packages/app-components/WhatsAppWidget";
//import AlertWidget from "./packages/app-components/AlertWidget";

function App() {
  return (
    <div>
      <Router>
        <Header />
        {/* <AlertWidget /> */}
        <Switch>
          {routes.map(
            ({
              path,
              exact,
              component: Cmp,
              routes: childrenRoutes,
              ...rest
            }) => (
              <ProtectedRoute
                key={path}
                path={path}
                exact={!!exact}
                component={() => <Cmp {...rest} routes={childrenRoutes} />}
              />
            )
          )}
        </Switch>
        <Footer />
        <ScrollToTopWidget />
        <WhatsAppWidget />        
      </Router>
    </div>
  );
}

export default App;
