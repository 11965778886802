// @ packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @ own
import './styles.scss';

const Image = ({
  className,
  rounded,
  source,
  size,
  alt,
  ...rest
}) => (
  <img
    alt={alt}
    className={cn(
      'image',
      {
        [`image--${size}`]: size,
        'image--rounded': rounded,
      },
      className,
    )}
    src={source}
    {...rest}
  />
);

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  fullwidth: PropTypes.bool,
  rounded: PropTypes.bool,
  size: PropTypes.oneOf(['mini', 'xxs', 'xs', 'xsm', 'sm', 'md', 'lg', 'fullwidth']),
  source: PropTypes.string.isRequired,
  onError: PropTypes.func,
};

Image.defaultProps = {
  alt: '',
  className: '',
  rounded: false,
  size: 'fullwidth',
  onError: () => {},
};

export default Image;
