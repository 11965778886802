// @ packages
import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// @ own
import "./styles.scss";
import { footerLinksDesktop, footerLinksMobile } from "./footerLinks";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
} from "../../packages/utils/constants";

const Footer = ({ className }) => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;
  const footerLinks = matchesMobile ? footerLinksMobile : footerLinksDesktop;

  return (
    <Navbar expand="lg" className={cn("footer", `footer--${size}`, className)}>
      {footerLinks.map(function (item, i) {
        return item.title.includes("WhatsApp") ? (
          <span className="btn" key={i}>
            <a
              key={i}
              className="nav__link"
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={item.icon} /> {item.title}
            </a>
          </span>
        ) : item.title ? (
          <span className="btn" key={i}>
            <FontAwesomeIcon icon={item.icon} />
            <Link className="nav__link" to={item.link}>
              {" "}
              {item.title}
            </Link>
          </span>
        ) : (
          <a
            key={i}
            className="nav__link"
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={item.icon} />
          </a>
        );
      })}
    </Navbar>
  );
};

export default Footer;
