
import robleRustico from "../../../../assets/images/floors/floating/models/8mm/roble_rustico.jpg";
import robleGris from "../../../../assets/images/floors/floating/models/8mm/roble_gris.jpg";
import robleAssago from "../../../../assets/images/floors/floating/models/8mm/roble_assago.jpg";
import robleEstrassburgo from "../../../../assets/images/floors/floating/models/8mm/roble_estrassburgo.jpg";
import haya from "../../../../assets/images/floors/floating/models/8mm/haya.jpg";
import robleLucerna from "../../../../assets/images/floors/floating/models/8mm/roble_lucerna.jpg";
import robleHacienda from "../../../../assets/images/floors/floating/models/8mm/roble_hacienda.jpg";
import fresnoNordico from "../../../../assets/images/floors/floating/models/8mm/fresno_nordico.jpg";
//import merbau from "../../../../assets/images/floors/floating/models/8mm/merbau.jpg";
//import jatobaMatogroso from "../../../../assets/images/floors/floating/models/8mm/jatoba_matogroso.jpg";
import nogalRojo from "../../../../assets/images/floors/floating/models/8mm/nogal_rojo.jpg";
import robleNewYork from "../../../../assets/images/floors/floating/models/8mm/roble_newyork.jpg";

const modelosCielorrasosPvc = [
    {
        alt: 'Roble Rústico AC4',
        image: robleRustico, 
        //price: 'm²: US$ 21,91 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Rústico AC4',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
        ]   
    },
    {
        alt: 'Roble Gris AC4',
        image: robleGris, 
        //price: 'm²: US$ 21,91 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Gris AC4',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
        ]   
    },
    {
        alt: 'Roble Assago AC4',
        image: robleAssago, 
        //price: 'm²: US$ 21,91 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Assago AC4',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
        ]   
    },
    {
        alt: 'Roble Estrassburgo AC4',
        image: robleEstrassburgo, 
        //price: 'm²: US$ 24,10 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Estrassburgo AC4',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
        ]   
    },    
    {
        alt: 'Haya AC4',
        image: haya, 
        //price: 'm²: US$ 24,10 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Haya AC4',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
        ]   
    },
    {
        alt: 'Roble Lucerna AC4',
        image: robleLucerna, 
        //price: 'm²: US$ 24,10 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Lucerna AC4',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
        ]   
    },
    {
        alt: 'Roble Hacienda AC4',
        image: robleHacienda, 
        //price: 'm²: US$ 24,10 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Hacienda AC4',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
        ]   
    },
    {
        alt: 'Fresno Nórdico AC4',
        image: fresnoNordico, 
        //price: 'm²: US$ 24,10 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Fresno Nórdico AC4',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
        ]   
    },
    /*{
        alt: 'Merbau AC4',
        image: merbau, 
        price: 'm²: US$ 24,10 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Merbau AC4',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
        ]   
    },
    {
        alt: 'Jatoba Matogroso AC4',
        image: jatobaMatogroso, 
        price: 'm²: US$ 24,10 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Jatoba Matogroso AC4',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
        ]   
    },*/
    {
        alt: 'Nogal Rojo AC4',
        image: nogalRojo, 
        //price: 'm²: US$ 24,10 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Nogal Rojo AC4',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
        ]   
    },
    {
        alt: 'Roble New York AC4',
        image: robleNewYork, 
        //price: 'm²: US$ 24,10 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble New York AC4',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
        ]   
    },
];

export default modelosCielorrasosPvc;