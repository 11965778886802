import img1 from "../../../assets/images/floors/floating/pisos-flotantes.jpg";
import img2 from "../../../assets/images/floors/vinyl/pisos-vinilicos.jpg";
import img3 from "../../../assets/images/floors/offers/ofertas_pisos.jpg";
import img4 from "../../../assets/images/floors/baseboards/zocalos.jpg";
import img5 from "../../../assets/images/floors/syntheticgrass/cesped-sintetico.jpg";
import img6 from "../../../assets/images/floors/deck/deck.jpg";

const categories = [
  {
    image: img1,
    url: "pisos-flotantes",
    title: "Pisos Flotantes",
    description:
      "Elección indiscutida a la hora de pensar en pisos por la calidez de sus diseños de madera y el amplio abanico de tonalidades.",
  },
  {
    image: img2,
    url: "pisos-a-prueba-de-agua",
    title: "Pisos a prueba de agua",
    description:
      "Compuestos por material 100% de PVC y utilizados en la instalación de suelos con un alto rendimiento.",
  },
  {
    image: img3,
    url: "ofertas",
    title: "Ofertas",
    description: "Ofertas imperdibles de lotes discontinuos de pisos hasta agotar stock.",
  },
  {
    image: img4,
    url: "zocalos-y-accesorios",
    title: "Zócalos y accesorios",
    description: "Todo lo que necesitás para la instalación de tus hermosos pisos.",
  },   
  {
    image: img5,
    url: "cesped-sintetico",
    title: "Césped Sintético",
    description: "Ideal para cualquier superficie y clima. Sin necesidad de mantenimiento. Y gran durabilidad.",
  },
  {
    image: img6,
    url: "deck-vinilico",
    title: "Deck vinílico",
    description: "Producto fabricado de 100% de PVC rígido extruido resistente a la intemperie y sin mantenimiento.",
  },
];

export default categories;
