import pvcBlanco7 from "../../../../assets/images/pvc/ceilings/models/pvcBlanco7.jpg";
import pvcBlanco10 from "../../../../assets/images/pvc/ceilings/models/pvcBlanco10.jpg";
import similMaderaGris from "../../../../assets/images/pvc/ceilings/models/similMaderaGris.jpg";
import similMaderaCedro from "../../../../assets/images/pvc/ceilings/models/similMaderaCedro.jpg";
import similMaderaCarmin from "../../../../assets/images/pvc/ceilings/models/similMaderaCarmin.jpg";
import similMaderaHaya from "../../../../assets/images/pvc/ceilings/models/similMaderaHaya.jpg";
import similMaderaCerejeira from "../../../../assets/images/pvc/ceilings/models/similMaderaCerejeira.jpg";
import ladrillo from "../../../../assets/images/pvc/ceilings/models/ladrillo.jpg";
import rayadoElegante from "../../../../assets/images/pvc/ceilings/models/rayadoElegante.jpg";

const modelosCielorrasosPvc = [
    {
        alt: 'Pvc Blanco  7mm',
        image: pvcBlanco7, 
        price: ' $ - ', //1332 el m² (contado)
        isOffer: true,
        title: 'Pvc Blanco 7mm',
        items: [
            {
                value: 'Espesor',
                description: '7mm'
            },
            {
                value: 'Ancho',
                description: '20cm'
            },
            {
                value: 'Tablas',
                description: '4/5/6 mts'
            },
        ]   
    },
    /*{
        alt: 'Blanco 8mm',
        image: pvcBlanco10, 
        price: '$ - ', //1406 el m² (contado)
        isOffer: true,
        isOfferStock: true,
        title: 'Blanco 8mm',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
            {
                value: 'Ancho',
                description: '20cm'
            },
            {
                value: 'Tablas',
                description: '3,5/4/4,5/5/5,5/6 mts'
            },
        ]   
    },*/
    {
        alt: 'Blanco 8mm',
        image: pvcBlanco10, 
        price: '$ - ', //1635 el m² (contado)
        isOffer: true,
        title: 'Blanco 8mm',
        items: [
            {
                value: 'Espesor',
                description: '8mm'
            },
            {
                value: 'Ancho',
                description: '25cm'
            },
            {
                value: 'Tablas',
                description: '4/5/6 mts'
            },
        ]   
    },
    {
        alt: 'Pvc Blanco  10mm',
        image: pvcBlanco10, 
        price: ' $ - ', //1718 el m² (contado)
        isOffer: true,
        title: 'Pvc Blanco 10mm',
        items: [
            {
                value: 'Espesor',
                description: '10mm'
            },
            {
                value: 'Ancho',
                description: '20cm'
            },
            {
                value: 'Tablas',
                description: '2/2,5/3/3,5/4/4,5/5/5,5/6 mts'
            },
        ]   
    },
    {
        alt: 'Blanco 13mm',
        image: pvcBlanco10, 
        //price: '$1127 el m² (contado)',
        title: 'Blanco 13mm',
        items: [
            {
                value: 'Espesor',
                description: '13mm'
            },
            {
                value: 'Ancho',
                description: '20cm'
            },
            {
                value: 'Tablas',
                description: '3,5/4/4,5/5/5,5/6 mts'
            },
        ]   
    },
    {
        alt: 'Simil Madera Gris Perlado',
        image: similMaderaGris, 
        price: '$ - ', //2220 el m² (contado)
        title: 'Simil Madera Gris Perlado',
        items: [
            {
                value: 'Espesor',
                description: '10mm'
            },
            {
                value: 'Ancho',
                description: '20cm'
            },
            {
                value: 'Tablas',
                description: '4/5/6 mts'
            },
        ]   
    },
    {
        alt: 'Simil Madera Cedro',
        image: similMaderaCedro, 
        price: '$ - ', //2220 el m² (contado)
        title: 'Simil Madera Cedro',
        items: [
            {
                value: 'Espesor',
                description: '10mm'
            },
            {
                value: 'Ancho',
                description: '20cm'
            },
            {
                value: 'Tablas',
                description: '4/5/6 mts'
            },
        ]   
    },
    {
        alt: 'Simil Madera Haya',
        image: similMaderaHaya, 
        price: '$ - ', //2220 el m² (contado)
        title: 'Simil Madera Haya',
        items: [
            {
                value: 'Espesor',
                description: '10mm'
            },
            {
                value: 'Ancho',
                description: '20cm'
            },
            {
                value: 'Tablas',
                description: '4/5/6 mts'
            },
        ]   
    },
    {
        alt: 'Simil Madera Carmin',
        image: similMaderaCarmin, 
        price: '$ - ', //2616 el m² (contado)
        title: 'Simil Madera Carmin',
        items: [
            {
                value: 'Espesor',
                description: '10mm'
            },
            {
                value: 'Ancho',
                description: '20cm'
            },
            {
                value: 'Tablas',
                description: '4/5/6 mts'
            },
        ]   
    },
    {
        alt: 'Simil Madera Cerejeira',
        image: similMaderaCerejeira, 
        price: '$ - ', //2616 el m² (contado)
        title: 'Simil Madera Cerejeira',
        items: [
            {
                value: 'Espesor',
                description: '10mm'
            },
            {
                value: 'Ancho',
                description: '20cm'
            },
            {
                value: 'Tablas',
                description: '4/5/6 mts'
            },
        ]   
    },
    {
        alt: 'Ladrillo',
        image: ladrillo, 
        price: '$ - ', //3404 el m² (contado)
        title: 'Ladrillo',
        items: [
            {
                value: 'Espesor',
                description: '10mm'
            },
            {
                value: 'Ancho',
                description: '25cm'
            },
            {
                value: 'Tablas',
                description: '6 mts'
            },
        ]   
    },
    {
        alt: 'Rayado Elegante',
        image: rayadoElegante, 
        price: ' $ - ', //3404 el m² (contado)
        title: 'Rayado Elegante',
        items: [
            {
                value: 'Espesor',
                description: '10mm'
            },
            {
                value: 'Ancho',
                description: '25cm'
            },
            {
                value: 'Tablas',
                description: '6 mts'
            },
        ]   
    },
];

export default modelosCielorrasosPvc;