import galeryCurtains1 from "../../../../assets/images/pvc/curtains/galery/galeryCurtains1.jpg";
import galeryCurtains2 from "../../../../assets/images/pvc/curtains/galery/galeryCurtains2.jpg";
import galeryCurtains3 from "../../../../assets/images/pvc/curtains/galery/galeryCurtains3.jpg";
import galeryCurtains4 from "../../../../assets/images/pvc/curtains/galery/galeryCurtains4.jpg";
import galeryCurtains5 from "../../../../assets/images/pvc/curtains/galery/galeryCurtains5.jpg";

const photos = [
    {
      src: galeryCurtains1,
      width: 3,
      height: 3
    },
    {
      src: galeryCurtains2,
      width: 4,
      height: 3
    },
    {
      src: galeryCurtains3,
      width: 1,
      height: 1
    },
    {
      src: galeryCurtains4,
      width: 4,
      height: 3
    },
    {
      src: galeryCurtains5,
      width: 3,
      height: 2
    },
  ];

export default photos;