import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//own
import "./styles.scss";
import logo from "../../../assets/images/logos/logo-horizontal-mini-.jpg";
import us from "../../../assets/images/about/galery/galeryAbout2.jpg";
import ContactCards from "./ContactCards";
import Container from "../../../containers/Container";
import HorizontalCard from "../../../packages/base-components/HorizontalCard";
import MainTitle from "../../../packages/app-components/MainTitle";
import ImageSection from "../../../packages/app-components/ImageSection";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
} from "../../../packages/utils/constants";

const Contact = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
  <Container>
    <div className={cn("contact", `contact--${size}`)}>
      <ImageSection imageSrc={logo} imageAlt="ALCAR BAHÍA" className="contact__image" />
      <ContactCards className="contact__cards" />
      <MainTitle>¿Cómo llegar a nuestro local?</MainTitle>
      <div className="contact__map">
        <iframe
          title="Visitá ALCAR BAHÍA"
          className="col-md-12 visible-xs-12 contact__map__iframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.3089519642285!2d-62.266465999999966!3d-38.73367199999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95edbb7d8fec51d1%3A0xc691da42ecec15fd!2sAlcar+Bahia+-+Sistemas+de+Construcci%C3%B3n+en+Seco!5e0!3m2!1ses!2sar!4v1419970405427"
          width="600"
          height="450"
          SameSite="None"
        ></iframe>
      </div>
      <HorizontalCard
          title="¿Quiénes somos?"
          description="Te invitamos a leer sobre nosotros y conocer nuestra trayectoria."
          imageAlt="ALCAR BAHÍA"
          imageSrc={us}
          textButton="Conoce más sobre ALCAR BAHÍA"
          linkButton="/quienes-somos"
          navigatePage 
          className="home__horizontal-card-empresa"
        />
    </div>
  </Container>
);
  }

export default Contact;
