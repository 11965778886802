import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 640,
      md: 1025,
      lg: 1845,
    },
  },
});
export const MATCHES_MOBILE = theme.breakpoints.down('sm');
export const MATCHES_DESKTOP = theme.breakpoints.up('md');
export const SIZE_MOBILE = 'sm';
export const SIZE_DESKTOP = 'lg';

export const SMALL_CONTENT = "small-content";
export const MEDIUM_CONTENT = "medium-content";
export const LARGE_CONTENT = "large-content";
export const EXTRA_LARGE_CONTENT = "extra-large-content";





