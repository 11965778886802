/* Images Accesorios */
import perfilF from "../../../../assets/images/pvc/ceilings/accesories/perfilF.jpg";
import perfilJ from "../../../../assets/images/pvc/ceilings/accesories/perfilJ.jpg";
import perfilMoldura from "../../../../assets/images/pvc/ceilings/accesories/perfilMoldura.jpg";
import perfilUnionFlexible from "../../../../assets/images/pvc/ceilings/accesories/perfilUnionFlexible.jpg";
import perfilUnionRigida from "../../../../assets/images/pvc/ceilings/accesories/perfilUnionRigida.jpg";


const accesories = [
    {
        image: perfilF,
        alt: 'Perfil F', 
        price: 'Blanco: $ - , Ocre: $ - ', // 896 - 1088 (contado)
        title: 'Perfil F',
        items: [
            {
                value: 'Colores',
                description: 'Blanco / Ocre'
            },
            {
                value: 'Medida',
                description: '6 mts' 
            }
        ],
        refColorOcre: true,
    },
    {
        image: perfilJ,
        alt: 'Perfil J', 
        price: 'Blanco: $ - , Ocre: $ - ', // 896 - 1234 (contado)
        title: 'Perfil J',
        items: [
            {
                value: 'Colores',
                description: 'Blanco / Ocre'
            },
            {
                value: 'Medida',
                description: '6 mts' 
            }
        ],
        refColorOcre: true, 
    },
    {
        image: perfilMoldura,
        alt: 'Perfil Moldura', 
        price: 'Blanco: $ - , Ocre: $ - ', // 1171 - 1395 (contado)
        title: 'Perfil Moldura',
        items: [
            {
                value: 'Colores',
                description: 'Blanco / Ocre'
            },
            {
                value: 'Medida',
                description: '6 mts' 
            }
        ],
        refColorOcre: true,
    },
    {
        image: perfilUnionFlexible,
        alt: 'Perfil Unión Flexible', 
        price: 'Blanco: $ - , Ocre: $ - ', // 1489 - 1799 (contado)
        title: 'Perfil Unión Flexible',
        items: [
            {
                value: 'Colores',
                description: 'Blanco / Ocre'
            },
            {
                value: 'Medida',
                description: '6 mts'
            }
        ],
        refColorOcre: true,
    },
    {
        image: perfilUnionRigida,
        alt: 'Perfil Unión Rígida', 
        price: 'Blanco: $ - , Ocre: $ - ', // 1061 - 1277 (contado)
        title: 'Perfil Unión Rígida',
        items: [
            {
                value: 'Colores',
                description: 'Blanco / Ocre'
            },
            {
                value: 'Medida',
                description: '1 mt' 
            }
        ], 
        refColorOcre: true,
    },
];

export default accesories;