import bisque from "../../../../assets/images/floors/vinyl/models/sistema-encastre/bisque.jpg";
import silver from "../../../../assets/images/floors/vinyl/models/sistema-encastre/silver.jpg";
import mocha from "../../../../assets/images/floors/vinyl/models/sistema-encastre/mocha.jpg";
import amber from "../../../../assets/images/floors/vinyl/models/sistema-encastre/amber.jpg";
import grisAcero from "../../../../assets/images/floors/vinyl/models/sistema-encastre/gris_acero.jpg";
import grisPlatino from "../../../../assets/images/floors/vinyl/models/sistema-encastre/gris_platino.jpg";
import hierroAntiguo from "../../../../assets/images/floors/vinyl/models/sistema-encastre/hierro_antiguo.jpg";
import nogalOtranto from "../../../../assets/images/floors/vinyl/models/sistema-encastre/nogal_otranto.jpg";
import robleNesso from "../../../../assets/images/floors/vinyl/models/sistema-encastre/roble_nesso.jpg";
import robleVarenna from "../../../../assets/images/floors/vinyl/models/sistema-encastre/roble_varenna.jpg";
import oroSuave from "../../../../assets/images/floors/vinyl/models/sistema-encastre/oro_suave.jpg";
import pinoVisby from "../../../../assets/images/floors/vinyl/models/sistema-encastre/pino_visby.jpg";
import pinoHelsinki from "../../../../assets/images/floors/vinyl/models/sistema-encastre/pino_helsinki.jpg";
import pinoBergen from "../../../../assets/images/floors/vinyl/models/sistema-encastre/pino_bergen.jpg";
import pinoOdense from "../../../../assets/images/floors/vinyl/models/sistema-encastre/pino_odense.jpg";
import robleApalache from "../../../../assets/images/floors/vinyl/models/sistema-encastre/roble_apalache.jpg";
import robleAtrani from "../../../../assets/images/floors/vinyl/models/sistema-encastre/roble_atrani.jpg";
import robleCalifornia from "../../../../assets/images/floors/vinyl/models/sistema-encastre/roble_california.jpg";
import robleCamel from "../../../../assets/images/floors/vinyl/models/sistema-encastre/roble_camel.jpg";
import robleDetroit from "../../../../assets/images/floors/vinyl/models/sistema-encastre/roble_detroit.jpg";
import robleAlaska from "../../../../assets/images/floors/vinyl/models/sistema-encastre/roble_alaska.jpg";
import robleFox from "../../../../assets/images/floors/vinyl/models/sistema-encastre/roble_fox.jpg";
import robleMillenium from "../../../../assets/images/floors/vinyl/models/sistema-encastre/roble_millenium.jpg";
import robleUmber from "../../../../assets/images/floors/vinyl/models/sistema-encastre/roble_umber.jpg";

const modelosVinylSistemaEncastre = [
        {
        alt: 'Bisque SP005',
        image: bisque, 
        //price: 'm²: US$ 28,51 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Bisque SP005',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Silver SP006',
        image: silver, 
        //price: 'm²: US$ 28,51 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Silver SP006',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Mocha SP007',
        image: mocha, 
        //price: 'm²: US$ 28,51 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Mocha SP007',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Amber SP008',
        image: amber, 
        //price: 'm²: US$ 28,51 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Amber SP008',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Gris Acero – SP021',
        image: grisAcero, 
        //price: 'm²: US$ 27,21 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Gris Acero – SP021',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '304.8 x 609.6mm'
            },
        ]   
    },
    {
        alt: 'Gris Platino - SP022',
        image: grisPlatino, 
        //price: 'm²: US$ 27,21 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Gris Platino- SP022',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '304.8 x 609.6mm'
            },
        ]   
    },
    {
        alt: 'Oro Suave – SP023',
        image: oroSuave, 
        //price: 'm²: US$ 27,21 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Oro Suave – SP023',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '304.8 x 609.6mm'
            },
        ]   
    },
    {
        alt: 'Hierro Antiguo - SP024',
        image: hierroAntiguo, 
        //price: 'm²: US$ 27,21 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Hierro Antiguo- SP024',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '304.8 x 609.6mm'
            },
        ]   
    },        
    {
        alt: 'Nogal Otranto',
        image: nogalOtranto, 
        //price: 'm²: US$ 29,81 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Nogal Otranto',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Roble Nesso – SP014',
        image: robleNesso, 
        //price: 'm²: US$ 29,81 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Nesso – SP014',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Roble Varenna – SP015',
        image: robleVarenna, 
        //price: 'm²: US$ 29,81 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Varenna – SP015',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Pino Visby SP009',
        image: pinoVisby, 
        //price: 'm²: US$ 28,51 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Pino Visby SP009',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Pino Helsinski SP010',
        image: pinoHelsinki, 
        //price: 'm²: US$ 28,51 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Pino Helsinski SP010',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Pino Bergen SP011',
        image: pinoBergen, 
        //price: 'm²: US$ 28,51 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Pino Bergen SP011',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Pino Odense SP012',
        image: pinoOdense, 
        //price: 'm²: US$ 28,51 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Pino Odense SP012',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Roble Apalache',
        image: robleApalache, 
        //price: 'm²: US$ 29,81 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Apalache',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '181 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Roble Atrani – SP016',
        image: robleAtrani, 
        //price: 'm²: US$ 29,81 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Atrani – SP016',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Roble California – SPC3672',
        image: robleCalifornia, 
        //price: 'm²: US$ 29,81 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble California – SPC3672',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '181 x 1220mm'
            },
        ]   
    },    
    {
        alt: 'Roble Detroit – SPC3678-3',
        image: robleDetroit, 
        //price: 'm²: US$ 29,81 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Detroit – SPC3678-3',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '181 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Roble Alaska – SPC3672-5',
        image: robleAlaska, 
        //price: 'm²: US$ 29,81 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Alaska – SPC3672-5',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '181 x 1220mm'
            },
        ]   
    },
    {
        alt: 'Roble Camel – SP004',
        image: robleCamel, 
        //price: 'm²: US$ 31,10 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Camel – SP004',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1535mm'
            },
        ]   
    },
    {
        alt: 'Roble Fox – SP001',
        image: robleFox, 
        //price: 'm²: US$ 31,10 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Fox – SP001',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1535mm'
            },
        ]   
    },
    {
        alt: 'Roble Millenium – SP002',
        image: robleMillenium, 
        //price: 'm²: US$ 31,10 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Millenium – SP002',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1535mm'
            },
        ]   
    },   
    {
        alt: 'Roble Umber – SP003',
        image: robleUmber, 
        //price: 'm²: US$ 31,10 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Umber – SP003',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
            {
                value: 'Medida',
                description: '180 x 1535mm'
            },
        ]   
    },
];

export default modelosVinylSistemaEncastre;