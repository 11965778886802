import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

//own
import "./styles.scss";
import puertasPvc from "../../../../assets/images/pvc/puertas.jpg";
import modelosPuertasPvc from "./models";
import Container from "./../../../../containers/Container";
import MainTitle from "./../../../../packages/app-components/MainTitle";
import ImageSection from "./../../../../packages/app-components/ImageSection";
import ListProductCard from "../../../../packages/app-components/ListProductCard";
import galleryPhotos from "./galeryPhotos";
import ResponsiveGallery from "../../../../packages/base-components/ResponsiveGallery";
import Text from "../../../../packages/base-components/Text";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
} from "../../../../packages/utils/constants";

const Doors = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("doors", `doors--${size}`)}>
        <ImageSection imageSrc={puertasPvc} imgAlt="Puertas Plegables PVC" />
        <MainTitle>Modelos de Puertas Plegables de PVC</MainTitle>
        <Text variant="body">
          {
            <div>
              La puerta plegable de PVC es recomendable para interiores,
              proporcionando belleza y practicidad de instalación y mantención.
              Entre sus ventajas principales:
              <ul className="doors__list">
                <li>
                <FontAwesomeIcon icon={faCheck} className="doors__list__icon"/> Son silenciosas, se deslizan
                  suavemente y pueden ser cerradas en ambas direcciones.
                </li>
                <li>
                <FontAwesomeIcon icon={faCheck} className="doors__list__icon"/> Son producidas en diferentes
                  anchos y se adaptan a cualquier espacio.
                </li>
              </ul>
            </div>
          }
        </Text>
        <ListProductCard products={modelosPuertasPvc} className="doors__models"></ListProductCard>

        <MainTitle>Galería</MainTitle>
        <ResponsiveGallery photos={galleryPhotos} />
      </div>
    </Container>
  );
};

export default Doors;
