import galeryFloating1 from "../../../../assets/images/floors/floating/galery/galeryFloating1.jpg";
import galeryFloating2 from "../../../../assets/images/floors/floating/galery/galeryFloating2.jpg";
import galeryFloating3 from "../../../../assets/images/floors/floating/galery/galeryFloating3.jpg";
import galeryFloating4 from "../../../../assets/images/floors/floating/galery/galeryFloating4.jpg";
import galeryFloating5 from "../../../../assets/images/floors/floating/galery/galeryFloating5.jpg";
import galeryFloating6 from "../../../../assets/images/floors/floating/galery/galeryFloating6.jpg";
import galeryFloating7 from "../../../../assets/images/floors/floating/galery/galeryFloating7.jpg";
import galeryFloating8 from "../../../../assets/images/floors/floating/galery/galeryFloating8.jpg";
import galeryFloating9 from "../../../../assets/images/floors/floating/galery/galeryFloating9.jpg";

const photos = [
    {
      src: galeryFloating1,
      width: 4,
      height: 3
    },
    {
      src: galeryFloating2,
      width: 4,
      height: 3
    },
    {
      src: galeryFloating3,
      width: 4,
      height: 3
    },
    {
      src: galeryFloating4,
      width: 4,
      height: 3
    },
    {
      src: galeryFloating5,
      width: 3,
      height: 3
    },
    {
      src: galeryFloating6,
      width: 4,
      height: 3
    },
    {
      src: galeryFloating7,
      width: 4,
      height: 3
    },
    {
      src: galeryFloating8,
      width: 4,
      height: 3
    },
    {
      src: galeryFloating9,
      width: 4,
      height: 3
    },
  ];

export default photos;