// @ packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useMediaQuery from "@material-ui/core/useMediaQuery";

// @ own
import './styles.scss';
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
} from "../../../../packages/utils/constants";

const SquareCard = ({
  className,
  description,
  imageSrc,
  title,
  url,
  ...rest
}) => {
  const renderDescription = () => (<div className="square-card__description">{description}</div>);
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <div
      className={cn(
        'square-card',
        `square-card--${size}`,
        className,
      )}
      data-ui-creative-name={title}
      data-ui-creative-resource={imageSrc}
      {...rest}
    >
      <div className="square-card__image-container">
        <img alt={title} src={imageSrc} className="square-card__image" />
      </div>
      <div className="square-card__content">
        <div className="square-card__title">
          {title}
        </div>
        {renderDescription()}
      </div>
    </div>
  );
};

SquareCard.propTypes = {
  className: PropTypes.string,
  //description: PropTypes.string.isRequired,
  //imageSrc: PropTypes.string.isRequired,
  //title: PropTypes.string.isRequired,
};

SquareCard.defaultProps = {
  className: '',
};

export default SquareCard;
