const advantages = [
    'Excelente terminación y calidad, de gran durabilidad.',
    'Su practicidad y facilidad de instalación y limpieza.',
    'No requieren mantenimiento.',
    'Tienen buen aspecto estético.',
    'Son aislantes térmicos, eléctricos y acústicos.',
    'Son resistentes al fuego, al agua (no se pudren) y a distintos agentes químicos.',
    'Tienen buena resistencia mecánica y al impacto.',
    'Son 100% reciclables.',
    'Aprobados por SENASA y clasificados por INTI CECON como “Autoextinguibles”. Por ende, aptos para establecimientos que requieren condiciones extremas de higiene como plantas procesadoras de alimentos, laboratorios y hospitales.',
];

export default advantages;