import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//own
import Image from "../../base-components/Image";
import "./styles.scss";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
} from "../../../packages/utils/constants";

const ImageSection = (props) => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <div className={cn("image-section", `image-section--${size}`,  props.className)}>
      <Image source={props.imageSrc} alt={props.imgAlt} />
    </div>
  );
};
export default ImageSection;
