import galeryDoors1 from '../../../../assets/images/pvc/doors/galery/galeryDoors1.jpg';
import galeryDoors2 from '../../../../assets/images/pvc/doors/galery/galeryDoors2.jpg';
import galeryDoors3 from '../../../../assets/images/pvc/doors/galery/galeryDoors3.jpg';
import galeryDoors4 from '../../../../assets/images/pvc/doors/galery/galeryDoors4.jpg';
import galeryDoors5 from '../../../../assets/images/pvc/doors/galery/galeryDoors5.jpg';

const photos = [
    {
      src: galeryDoors1,
      width: 4,
      height: 3
    },
    {
      src: galeryDoors2,
      width: 4,
      height: 3
    },
    {
      src: galeryDoors3,
      width: 3,
      height: 3
    },
    {
      src: galeryDoors4,
      width: 3,
      height: 3
    },
    {
      src: galeryDoors5,
      width: 3,
      height: 3
    },    
  ];

export default photos;