import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//own
import "./styles.scss";
import cespedSintetico from "../../../../assets/images/floors/syntheticgrass/cesped-sintetico.jpg";
import modelosCespedSintetico from "./models";
import Container from "./../../../../containers/Container";
import MainTitle from "./../../../../packages/app-components/MainTitle";
import ListProductCard from "../../../../packages/app-components/ListProductCard";
import galleryPhotos from "./galeryPhotos";
import ResponsiveGallery from "../../../../packages/base-components/ResponsiveGallery";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
  SMALL_CONTENT
} from "../../../../packages/utils/constants";
import ImageSection from "../../../../packages/app-components/ImageSection";


const SyntheticGrass = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("synthetic-grass", `floating--${size}`)}>
        <ImageSection imageSrc={cespedSintetico} imgAlt="Césped Sintético"/>
        <MainTitle>Conocé nuestros modelos de Césped Sintético</MainTitle>    
        <ListProductCard products={modelosCespedSintetico} sizeContent={SMALL_CONTENT}></ListProductCard>
        
        <MainTitle>Galería</MainTitle>
        <ResponsiveGallery photos={galleryPhotos} />
      </div>
    </Container>
  );
};

export default SyntheticGrass;
