import img1 from '../../../../assets/images/slide1.jpg';
import img2 from '../../../../assets/images/slide2.jpg';
import img3 from '../../../../assets/images/slide3.jpg';
import img4 from '../../../../assets/images/slide4.jpg';
import img5 from '../../../../assets/images/slide5.jpg';

import imgMobile1 from '../../../../assets/images/slideMobile1.jpg';
import imgMobile2 from '../../../../assets/images/slideMobile2.jpg';
import imgMobile3 from '../../../../assets/images/slideMobile3.jpg';
import imgMobile4 from '../../../../assets/images/slideMobile4.jpg';
import imgMobile5 from '../../../../assets/images/slideMobile5.jpg';

const data = {
  desktop: [
    {
      img: img1,
      alt: 'Placas Antihumedad TAUROC',
    },
    {
      img: img2,
      alt: 'Placas Antihumedad TAUROC',
    },
    {
      img: img3,
      alt: 'Cielorrasos y Revestimientos PVC',
      legend: 'Cielorrasos y Revestimientos PVC',
    },
    {
      img: img4,
      alt: 'Cielorrasos y Revestimientos PVC',
      legend: 'Cielorrasos y Revestimientos PVC',
    },
    {
      img: img5,
      alt: 'Pisos de madera y a prueba de agua',
      legend: 'Pisos de madera y a prueba de agua',
    }
  ],
  mobile: [
    {
      img: imgMobile1,
      alt: 'Placas Antihumedad TAUROC',
    },
    {
      img: imgMobile2,
      alt: 'Placas Antihumedad TAUROC',
    },
    {
      img: imgMobile3,
      alt: 'Cielorrasos y Revestimientos PVC',
      legend: 'Cielorrasos y Revestimientos PVC',
    },
    {
      img: imgMobile4,
      alt: 'Cielorrasos y Revestimientos PVC',
      legend: 'Cielorrasos y Revestimientos PVC',
    },
    {
      img: imgMobile5,
      alt: 'Pisos de madera y a prueba de agua',
      legend: 'Pisos de madera y a prueba de agua',
    }
  ]
}

export default data;