import React from "react";
import cn from "classnames";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//own
import "./styles.scss";
import cards from "./cards";

const ContactCards = ({ className }) => (
  <Row>
    <div className={cn("contact-cards", className)}>
      {cards.map(function (card, i) {
        return (
          <Col key={i} className="col" xs={12} sm={6} md={5} lg={3}>
            <Card className="contact-cards__card" key={i}>
              <div className="contact-cards__card__image" variant="top">
                <h3>{card.title}</h3>
                {card.icons.map(function (icon, id) {
                  return (
                    <FontAwesomeIcon
                      key={id}
                      icon={icon}
                      size="4x"
                      className="contact-cards__card__icon" 
                    />
                  );
                })}
              </div>
              <Card.Body className="contact-cards__card__body">
                <h5 className="contact-cards__card__subtitle">
                  {card.subtitle}
                </h5>
                <Card.Text className="contact-cards__card__text">
                  {card.text}
                </Card.Text>
                <div className="contact-cards__card__buttons">
                  {card.buttons.map(function (button, index) {
                    return (
                      <a
                        key={index}
                        className="btn btn-outline-secondary contact-cards__card__button"
                        href={button.linkButton}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {button.textButton}
                      </a>
                    );
                  })}
                </div>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </div>
  </Row>
);

export default ContactCards;
