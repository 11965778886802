import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

//own
import "./styles.scss";

const WhatsAppWidget = () => (
  <a
    href="https://api.whatsapp.com/send?phone=5492914634258&text=Hola ALCAR BAHIA!"
    className="whatsapp-widget__button"
    target="_blank"
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon
      icon={faWhatsapp}
      className="whatsapp-widget__icon"
    />
  </a>
);

export default WhatsAppWidget;
