// @ packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';


// @ own
import './styles.scss';
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
} from "../../../packages/utils/constants";

const Text = ({
  children,
  className,
  variant,
  bold,
  ...rest
}) => {
  const size = useMediaQuery(MATCHES_MOBILE) ? SIZE_MOBILE : SIZE_DESKTOP;
  return (
    <div
      className={cn(
        'text',
        `text__${variant}-${size}`,
        {
          'text--bold': bold,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};


Text.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  bold: PropTypes.bool,
  variant: PropTypes.string,
};

Text.defaultProps = {
  className: '',
  bold: false,
  variant: 'none',
};

export default Text;
