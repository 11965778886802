/* Images Accesorios */
import sellador_adhesivo from "../../../assets/images/antihumidity/accesories/sellador+adhesivo.jpg";
import bolsa from "../../../assets/images/antihumidity/accesories/bolsa.jpg";
import moldura from "../../../assets/images/antihumidity/accesories/moldura_yeso.jpg";


const accesories = [
    {
        image: sellador_adhesivo,
        alt: 'Sellador Adhesivo 2 en 1', 
        price: '$ - ', //5212 (contado)
        title: 'Sellador Adhesivo 2 en 1',
        items: [
            {
                value: 'Peso',
                description: '15kg'
            }
        ] 
    },
    {
        image: bolsa,
        alt: 'Sellador', 
        price: '$ - ', //329 (contado)
        title: 'Sellador',
        items: [
            {
                value: 'Peso',
                description: '1 kg'
            }
        ]  
    },
    {
        image: bolsa,
        alt: 'Adhesivo', 
        price: '$ - ', //243 (contado)
        title: 'Adhesivo',
        items: [
            {
                value: 'Peso',
                description: '1 kg'
            }
        ]  
    },
    {
        image: moldura,
        alt: 'Moldura Liso Medio de Yeso', 
        price: '$ - ', //456 (contado)
        title: 'Moldura Liso Medio de Yeso',
        items: [
            {
                value: 'Medida',
                description: '1 mt'
            }
        ]  
    },
    {
        image: moldura,
        alt: 'Moldura Liso Medio de Telgopor', 
        //price: '$200 (contado)',
        title: 'Moldura Liso Medio de Telgopor',
        items: [
            {
                value: 'Medida',
                description: '2 mts'
            }
        ]  
    },
    /*{
        image: moldura,
        alt: 'Baguetón de Yeso', 
        price: '$190 (contado)',
        title: 'Baguetón de Yeso',
        items: [
            {
                value: 'Medida',
                description: '1 mt'
            }
        ]  
    },
    {
        image: moldura,
        alt: 'Baguetón de Telgopor', 
        price: '$220 (contado)',
        title: 'Baguetón de Telgopor',
        items: [
            {
                value: 'Medida',
                description: '2 mt'
            }
        ]  
    },*/
];

export default accesories;