import galeryCoatings1 from "../../../../assets/images/pvc/coatings/galery/galeryCoatings1.jpg";
import galeryCoatings2 from "../../../../assets/images/pvc/coatings/galery/galeryCoatings2.jpg";
import galeryCoatings3 from "../../../../assets/images/pvc/coatings/galery/galeryCoatings3.jpg";
import galeryCoatings4 from "../../../../assets/images/pvc/coatings/galery/galeryCoatings4.jpg";
import galeryCoatings5 from "../../../../assets/images/pvc/coatings/galery/galeryCoatings5.jpg";

//works
import workPvcCoatings1 from "../../../../assets/images/pvc/coatings/galery/works/workPvcCoatings1.jpg";
import workPvcCoatings2 from "../../../../assets/images/pvc/coatings/galery/works/workPvcCoatings2.jpg";
import workPvcCoatings3 from "../../../../assets/images/pvc/coatings/galery/works/workPvcCoatings3.jpg";
import workPvcCoatings4 from "../../../../assets/images/pvc/coatings/galery/works/workPvcCoatings4.jpg";
import workPvcCoatings5 from "../../../../assets/images/pvc/coatings/galery/works/workPvcCoatings5.jpg";
import workPvcCoatings6 from "../../../../assets/images/pvc/coatings/galery/works/workPvcCoatings6.jpg";
import workPvcCoatings7 from "../../../../assets/images/pvc/coatings/galery/works/workPvcCoatings7.jpg";
import workPvcCoatings8 from "../../../../assets/images/pvc/coatings/galery/works/workPvcCoatings8.jpg";

const photos = [
    {
      src: galeryCoatings1,
      width: 4,
      height: 3
    },
    {
      src: galeryCoatings2,
      width: 1,
      height: 1
    },
    {
      src: galeryCoatings3,
      width: 4,
      height: 3
    },
    {
      src: galeryCoatings4,
      width: 3,
      height: 4
    },
    {
      src: galeryCoatings5,
      width: 4,
      height: 3
    },
    {
      src: workPvcCoatings1,
      width: 3,
      height: 3
    },
    {
      src: workPvcCoatings2,
      width: 3,
      height: 3
    },
    {
      src: workPvcCoatings3,
      width: 3,
      height: 3
    },
    {
      src: workPvcCoatings4,
      width: 3,
      height: 3
    },
    {
      src: workPvcCoatings5,
      width: 4,
      height: 3
    },
    {
      src: workPvcCoatings6,
      width: 4,
      height: 3
    },
    {
      src: workPvcCoatings7,
      width: 4,
      height: 3
    },
    {
      src: workPvcCoatings8,
      width: 4,
      height: 3
    },
  ];

export default photos;