// @ packages
import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";

// @ own
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
} from "../../../packages/utils/constants";

import "./styles.scss";

const HorizontalCard = ({
  className,
  imageAlt,
  imageSrc,
  title,
  description,
  textButton,
  linkButton,
  navigatePage,
}) => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <div
      className={cn("horizontal-card", `horizontal-card--${size}`, className)}
    >
      <img
        alt={imageAlt}
        src={imageSrc}
        className="horizontal-card__phone-desktop-image"
      />
      <div className="horizontal-card__info">
        <h4 className=" horizontal-card__info-title">{title}</h4>
        <span className="horizontal-card__info-description">{description}</span>
        {textButton && (
          <div>
            {navigatePage ? (
              <Link
                className="btn horizontal-card__info-button"
                to={linkButton}
              >
                {textButton}
              </Link>
            ) : (
              <a
                className="btn horizontal-card__info-button"
                href={linkButton}
                target="_blank"
                rel="noopener noreferrer"
              >
                {textButton}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HorizontalCard;
