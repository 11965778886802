// @ packages
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// @ own
import ScrollToTop from "./../../packages/base-components/ScrollToTop";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
} from "../../packages/utils/constants";
import "./styles.scss";

const Container = (props) => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <div className={cn("app-container", `app-container--${size}`)}>
      <ScrollToTop />
      <div className="app-container__content">{props.children}</div>      
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
};

Container.defaultProps = {
  children: null,
};

export default Container;
