import React from "react";
import cn from "classnames";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//own
import data from "./data";
import "./styles.scss";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
} from "../../../../packages/utils/constants";

const ResponsiveCarousel = ({ className }) =>{
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;
  const images = matchesMobile ? data.mobile : data.desktop;

  return (
  <Carousel
    showThumbs={false}
    showStatus={false}
    dynamicHeight={false}
    infiniteLoop
    autoPlay
    interval={4000}
    transitionTime={250}
    stopOnHover={true}
    useKeyboardArrows
    className={cn("presentation-mode", className)}
  >
    {images.map(function (item, i) {
      return (
        <div key={item.img}>
          <img alt={item.alt} src={item.img} />
          {item.legend &&
            (<p className={cn("legend", `legend--${size}`)}>{item.legend}</p>)
          }
        </div>
      );
    })}
  </Carousel>
);
  }

export default ResponsiveCarousel;
