import cesped1 from "../../../../assets/images/floors/syntheticgrass/models/cesped_decorativo.jpg";
import cesped2 from "../../../../assets/images/floors/syntheticgrass/models/cesped_sport.jpg";
import cesped3 from "../../../../assets/images/floors/syntheticgrass/models/cesped_verano_corto.jpg";
import cesped4 from "../../../../assets/images/floors/syntheticgrass/models/cesped_verano_largo.jpg";
import cesped5 from "../../../../assets/images/floors/syntheticgrass/models/cesped_otoño_corto.jpg";
import cesped6 from "../../../../assets/images/floors/syntheticgrass/models/cesped_otoño_largo.jpg";

const modelosCespedSintetico = [
    {
        alt: 'Decorativo',
        image: cesped1, 
        title: 'Decorativo',        
    },
    {
        alt: 'Sport',
        image: cesped2, 
        title: 'Sport',        
    },
    {
        alt: 'Verano Corto',
        image: cesped3, 
        title: 'Verano Corto',        
    },
    {
        alt: 'Verano Largo',
        image: cesped4, 
        title: 'Verano Largo',        
    },
    {
        alt: 'Otoño Corto',
        image: cesped5, 
        title: 'Otoño Corto',        
    },
    {
        alt: 'Otoño Largo',
        image: cesped6, 
        title: 'Otoño Largo',        
    },    
];

export default modelosCespedSintetico;