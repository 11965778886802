import adhesivoZocalos from "../../../../assets/images/floors/baseboards/accesories/adhesivo_zocalos.jpg";
import adhesivoZocalosAcetico from "../../../../assets/images/floors/baseboards/accesories/adhesivo_zocalos_acetico.jpg";
import clip from "../../../../assets/images/floors/baseboards/accesories/clip.jpg";
import tapaDesnivel from "../../../../assets/images/floors/baseboards/accesories/varilla_desnivel.jpg";
import tapaJuntas from "../../../../assets/images/floors/baseboards/accesories/varilla_tapajuntas.jpg";
import bajopiso from "../../../../assets/images/floors/baseboards/accesories/bajopiso.jpg";
import bajopisoAcustica from "../../../../assets/images/floors/baseboards/accesories/bajopiso_acustica.jpg";
import bajopisoVinilicos from "../../../../assets/images/floors/baseboards/accesories/bajopiso_vinilicos.jpg";

const accesories = [
    {
        alt: 'Adhesivo Rellenador para zócalos',
        image: adhesivoZocalos, 
        title: 'Adhesivo Rellenador para zócalos',
    },
    {
        alt: 'Adhesivo Rellenador acético',
        image: adhesivoZocalosAcetico, 
        title: 'Adhesivo Rellenador para zócalos acético',
    },
    {
        alt: 'Clip de fijación',
        image: clip, 
        title: 'Clip de fijación',
    },
    {
        alt: 'Varilla desnivel',
        image: tapaDesnivel, 
        title: 'Varilla desnivel',
    },
    {
        alt: 'Varilla tapajuntas',
        image: tapaJuntas, 
        title: 'Varilla tapajuntas',
    },
    {
        alt: 'Manta Bajo Piso Flotante',
        image: bajopiso, 
        title: 'Manta Bajo Piso Flotante',
    },
    {
        alt: 'Manta Bajo Piso Flotante Accoustic',
        image: bajopisoAcustica, 
        title: 'Manta Bajo Piso Flotante Accoustic',
    },
    {
        alt: 'Manta Silent Step para Pisos Vinílicos',
        image: bajopisoVinilicos, 
        title: 'Manta Silent Step para Pisos Vinílicos',
    },
];

export default accesories;