import productDrywall1 from "../../../assets/images/drywall/products/product1.jpg";
import productDrywall2 from "../../../assets/images/drywall/products/product2.jpg";
import productDrywall3 from "../../../assets/images/drywall/products/product3.jpg";
import productDrywall4 from "../../../assets/images/drywall/products/product4.jpg";
import productDrywall5 from "../../../assets/images/drywall/products/product5.jpg";
import productDrywall6 from "../../../assets/images/drywall/products/product6.jpg";
import productDrywall7 from "../../../assets/images/drywall/products/product7.jpg";
import productDrywall8 from "../../../assets/images/drywall/products/product8.jpg";
import productDrywall9 from "../../../assets/images/drywall/products/product9.jpg";
import productDrywall10 from "../../../assets/images/drywall/products/product10.jpg";
import productDrywall11 from "../../../assets/images/drywall/products/product11.jpg";
import productDrywall12 from "../../../assets/images/drywall/products/product12.jpg";
import productDrywall13 from "../../../assets/images/drywall/products/product13.jpg";
import productDrywall14 from "../../../assets/images/drywall/products/product14.jpg";
import productDrywall15 from "../../../assets/images/drywall/products/product15.jpg";
import productDrywall16 from "../../../assets/images/drywall/products/product16.jpg";
import productDrywall17 from "../../../assets/images/drywall/products/product17.jpg";
import productDrywall18 from "../../../assets/images/drywall/products/product18.jpg";
import productDrywall19 from "../../../assets/images/drywall/products/product19.jpg";
import productDrywall20 from "../../../assets/images/drywall/products/product20.jpg";
import productDrywall21 from "../../../assets/images/drywall/products/product21.jpg";
import productDrywall22 from "../../../assets/images/drywall/products/product22.jpg";
import productDrywall23 from "../../../assets/images/drywall/products/product23.jpg";
import productDrywall24 from "../../../assets/images/drywall/products/product24.jpg";
import productDrywall25 from "../../../assets/images/drywall/products/product25.jpg";
import productDrywall26 from "../../../assets/images/drywall/products/product26.jpg";
import productDrywall27 from "../../../assets/images/drywall/products/product27.jpg";
import productDrywall28 from "../../../assets/images/drywall/products/product28.jpg";
import productDrywall29 from "../../../assets/images/drywall/products/product29.jpg";
import productDrywall30 from "../../../assets/images/drywall/products/product30.jpg";
import productDrywall32 from "../../../assets/images/drywall/products/product32.jpg";
import productDrywall33 from "../../../assets/images/drywall/products/product33.jpg";
import productDrywall34 from "../../../assets/images/drywall/products/product34.jpg";
import productDrywall35 from "../../../assets/images/drywall/products/product35.jpg";

export const placasDrywall = [
  {
    alt: "Placas de Yeso",
    image: productDrywall1,
    title: "Placas de Yeso",
    items: [
      {
        value: "Detalle",
        description: "STD / RH / RF",
      },
      {
        value: "Medida",
        description: "1,20 mts x 2,40 mts",
      },
    ],
  },
  {
    alt: "Placas Desmontable",
    image: productDrywall2,
    title: "Placas Desmontable",
    items: [
      {
        value: "Espesor",
        description: "8,5 mm",
      },
      {
        value: "Medida",
        description: "1,22 mts x 0,61 mts",
      },
      {
        value: "Color",
        description: "Blanco",
      },
    ],
  },
  {
    alt: "Placa Siding para exterior",
    image: productDrywall17,
    title: "Placa Siding para exterior",
    items: [
      {
        value: "Largo",
        description: "75 ml/ 150 ml",
      },
    ],
  },
  {
    alt: "Placa Superboard para exterior",
    image: productDrywall18,
    title: "Placa Superboard para exterior",
    items: [
      {
        value: "Largo",
        description: "75 ml/ 150 ml",
      },
    ],
  },
  {
    alt: "Placa OSB",
    image: productDrywall19,
    title: "Placa OSB",
    items: [
      {
        value: "Largo",
        description: "75 ml/ 150 ml",
      },
    ],
  },
];

export const perfilesDrywall = [
  {
    alt: "Perfil Montante 34mm",
    image: productDrywall3,
    title: "Perfil Montante 34mm",
    items: [
      {
        value: "Largo",
        description: "2,6 / 3 / 4 mts",
      },
      {
        value: "Espesor",
        description: "0,52 mm",
      },
      {
        value: "Ancho",
        description: "34 mm",
      },
    ],
  },
  {
    alt: "Perfil Montante 69mm",
    image: productDrywall27,
    title: "Perfil Montante 69mm",
    items: [
      {
        value: "Largo",
        description: "2,6 mts",
      },
      {
        value: "Espesor",
        description: "0,52 mm",
      },
      {
        value: "Ancho",
        description: "69 mm",
      },
    ],
  },
  {
    alt: "Perfil Solera 35mm",
    image: productDrywall4,
    title: "Perfil Solera 35mm",
    items: [
      {
        value: "Largo",
        description: "2,6 mts",
      },
      {
        value: "Espesor",
        description: "0,52 mm",
      },
      {
        value: "Ancho",
        description: "35 mm",
      },
    ],
  },
  {
    alt: "Perfil Solera 70mm",
    image: productDrywall5,
    title: "Perfil Solera 70mm",
    items: [
      {
        value: "Largo",
        description: "2,6 mts",
      },
      {
        value: "Espesor",
        description: "0,52 mm",
      },
      {
        value: "Ancho",
        description: "70 mm",
      },
    ],
  },
  {
    alt: "Perfil Omega",
    image: productDrywall6,
    title: "Perfil Omega",
    items: [
      {
        value: "Largo",
        description: "3 mts",
      },
      {
        value: "Espesor",
        description: "0,94 mm",
      },
      {
        value: "Ancho",
        description: "70 mm",
      },
    ],
  },
  {
    alt: "Perfil Cantonera",
    image: productDrywall7,
    title: "Perfil Cantonera 31mm",
    items: [
      {
        value: "Largo",
        description: "2,6 mts",
      },
      {
        value: "Espesor",
        description: "0,38 mm",
      },
      {
        value: "Ancho",
        description: "31 mm",
      },
    ],
  },
  {
    alt: "Perfil Ángulo De Ajuste",
    image: productDrywall8,
    title: "Perfil Ángulo De Ajuste",
    items: [
      {
        value: "Largo",
        description: "2,6 mts",
      },
      {
        value: "Espesor",
        description: "0,38 mm",
      },
      {
        value: "Ancho",
        description: "25 mm",
      },
    ],
  },
  {
    alt: "Perfil Buña Z Perimetral",
    image: productDrywall9,
    title: "Perfil Buña Z Perimetral",
    items: [
      {
        value: "Largo",
        description: "2,6 mts",
      },
      {
        value: "Espesor",
        description: "0,44 mm",
      },
      {
        value: "Ancho",
        description: "35 mm",
      },
    ],
  },
  {
    alt: "Perfil Perimetral para Desmontable",
    image: productDrywall10,
    title: "Perfil Perimetral para Desmontable",
    items: [
      {
        value: "Medida",
        description: "3,05 mts",
      },
      {
        value: "Espesor",
        description: "0,44 mm",
      },
      {
        value: "Ancho",
        description: "24 mm",
      },
    ],
  },
  {
    alt: "Perfil Larguero para Desmontable",
    image: productDrywall11,
    title: "Perfil Larguero para Desmontable",
    items: [
      {
        value: "Largo",
        description: "3,66 mts",
      },
      {
        value: "Espesor",
        description: "0,29 mm",
      },
      {
        value: "Ancho",
        description: "24 mm",
      },
    ],
  },
  {
    alt: "Perfil Travesaño para Desmontable",
    image: productDrywall12,
    title: "Perfil Travesaño para Desmontable",
    items: [
      {
        value: "Corto / Largo",
        description: "0,61 mts / 1,22 mts",
      },
      {
        value: "Ancho",
        description: "24 mm",
      },
    ],
  },
];

export const accesoriosDrywall = [
  {
    alt: "Cinta microperforada de papel",
    image: productDrywall15,
    title: "Cinta microperforada de papel",
    items: [
      {
        value: "Largo",
        description: "23 / 75 / 150 mts",
      },
    ],
  },
  {
    alt: "Cinta tramada para pegar",
    image: productDrywall16,
    title: "Cinta tramada para pegar",
    items: [
      {
        value: "Largo",
        description: "20 / 45 / 90 mts",
      },
    ],
  },  
  {
    alt: "Tornillería",
    image: productDrywall30,
    title: "Tornillería",
    items: [
      {
        value: "Modelos",
        description: "autoperforantes y fijaciones para Construcción en seco",
      },
    ],
  },
];

export const masillasDrywall = [
  {
    alt: "Masilla para interior",
    image: productDrywall13,
    title: "Masilla para interior",
    items: [
      {
        value: "Baldes",
        description: "1,8 / 7 / 15 / 32 kg",
      },
    ],
  },
  {
    alt: "Masilla para exterior",
    image: productDrywall14,
    title: "Masilla para exterior",
    items: [
      {
        value: "Baldes",
        description: "7 / 16 / 32 kg",
      },
    ],
  },
  {
    alt: "Impermeabilizante para techos",
    image: productDrywall29,
    title: "Impermeabilizante para techos",
    items: [
      {
        value: "Volúmen",
        description: "4 / 10 / 20 lts",
      },
    ],
  },
];

export const moldurasDrywall = [
  {
    alt: "Moldura AT-04 Guarda",
    image: productDrywall20,
    title: "Moldura AT-04",
    items: [
      {
        value: "Largo",
        description: "2 mts",
      },
    ],
  },
  {
    alt: "Moldura AT-05 Guarda",
    image: productDrywall21,
    title: "Moldura AT-05",
    items: [
      {
        value: "Largo",
        description: "2 mts",
      },
    ],
  },
  {
    alt: "Moldura AT-31R ",
    image: productDrywall22,
    title: "Moldura AT-31R ",
    items: [
      {
        value: "Largo",
        description: "2 mts",
      },
    ],
  },
  {
    alt: "Moldura AT-35 ",
    image: productDrywall23,
    title: "Moldura AT-35 ",
    items: [
      {
        value: "Largo",
        description: "2 mts",
      },
    ],
  },
  {
    alt: "Moldura AT-40 ",
    image: productDrywall24,
    title: "Moldura AT-40 ",
    items: [
      {
        value: "Largo",
        description: "2 mts",
      },
    ],
  },
  {
    alt: "Moldura AT-46 ",
    image: productDrywall25,
    title: "Moldura AT-46 ",
    items: [
      {
        value: "Largo",
        description: "2 mts",
      },
    ],
  },
  {
    alt: "Moldura AT-50 ",
    image: productDrywall26,
    title: "Moldura AT-50 ",
    items: [
      {
        value: "Largo",
        description: "2 mts",
      },
    ],
  },
  {
    alt: "Adhesivo para Molduras",
    image: productDrywall28,
    title: "Adhesivo para Molduras",
    items: [
      {
        value: "Volúmen",
        description: "500 cc",
      },
    ],
  },
];

export const aislantesDrywall = [
  {
    alt: "Membrana aislante sin aluminio",
    image: productDrywall32,
    title: "Membrana aislante sin aluminio",
    items: [
      {
        value: "Espesor",
        description: "2 / 5 / 10 mm",
      },
      {
        value: "Rollo",
        description: "20 mts²",
      },
    ],
  },
  {
    alt: "Membrana aislante una cara aluminio",
    image: productDrywall33,
    title: "Membrana aislante una cara aluminio",
    items: [
      {
        value: "Espesor",
        description: "5 / 10 mm",
      },
      {
        value: "Rollo",
        description: "20 mts²",
      },
    ],
  },
  {
    alt: "Membrana aislante dos caras aluminio",
    image: productDrywall34,
    title: "Membrana aislante dos caras aluminio",
    items: [
      {
        value: "Espesor",
        description: "5 / 10 mm",
      },
      {
        value: "Rollo",
        description: "20 mts²",
      },
    ],
  },
  {
    alt: "Lana de vidrio con aluminio",
    image: productDrywall35,
    title: "Lana de vidrio con aluminio",
    items: [
      {
        value: "Espesor",
        description: "38 / 50 mm",
      },
      {
        value: "Rollo",
        description: "21,6 mts²",
      },
    ],
  },
];
