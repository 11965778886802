import galeryBaseboards1 from "../../../../assets/images/floors/baseboards/galery/galeryBaseboards1.jpg";
import galeryBaseboards2 from "../../../../assets/images/floors/baseboards/galery/galeryBaseboards2.jpg";
import galeryBaseboards3 from "../../../../assets/images/floors/baseboards/galery/galeryBaseboards3.jpg";
import galeryBaseboards4 from "../../../../assets/images/floors/baseboards/galery/galeryBaseboards4.jpg";
import galeryBaseboards5 from "../../../../assets/images/floors/baseboards/galery/galeryBaseboards5.jpg";
import galeryBaseboards6 from "../../../../assets/images/floors/baseboards/galery/galeryBaseboards6.jpg";
import galeryBaseboards7 from "../../../../assets/images/floors/baseboards/galery/galeryBaseboards7.jpg";


const photos = [
    {
      src: galeryBaseboards1,
      width: 4,
      height: 3
    },
    {
      src: galeryBaseboards2,
      width: 4,
      height: 4
    },
    {
      src: galeryBaseboards3,
      width: 4,
      height: 3
    },
    {
      src: galeryBaseboards4,
      width: 6,
      height: 4
    },
    {
      src: galeryBaseboards5,
      width: 5,
      height: 4
    },  
    {
      src: galeryBaseboards6,
      width: 5,
      height: 4
    },  
    {
      src: galeryBaseboards7,
      width: 5,
      height: 4
    },   
  ];

export default photos;