import React, { useState } from "react";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

//own
import "./styles.scss";

const ScrollToTopWidget = () => {
  const [showWidget, setShowWidget] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});    
  };

  const checkScrollTop = () => {      
    if (window.pageYOffset > 1000) {
      setShowWidget(true);
    } else {
      setShowWidget(false);
    }
  }

  window.addEventListener('scroll', checkScrollTop);

  return (
    <button
      onClick={scrollToTop}
      className={cn("scroll-to-top-widget__button", {
        "scroll-to-top-widget__button--hidden": !showWidget,
      })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={faChevronUp} className="scroll-to-top-widget__icon"/>
    </button>
  );
};

export default ScrollToTopWidget;
