import galeryAntihumidity1 from "../../../assets/images/antihumidity/galery/galeryAntihumidity1.jpg";
import galeryAntihumidity2 from "../../../assets/images/antihumidity/galery/galeryAntihumidity2.jpg";
import galeryAntihumidity3 from "../../../assets/images/antihumidity/galery/galeryAntihumidity3.jpg";
import galeryAntihumidity4 from "../../../assets/images/antihumidity/galery/galeryAntihumidity4.jpg";
import galeryAntihumidity5 from "../../../assets/images/antihumidity/galery/galeryAntihumidity5.jpg";
import galeryAntihumidity6 from "../../../assets/images/antihumidity/galery/galeryAntihumidity6.jpg";
import galeryAntihumidity7 from "../../../assets/images/antihumidity/galery/galeryAntihumidity7.jpg";
import galeryAntihumidity8 from "../../../assets/images/antihumidity/galery/galeryAntihumidity8.jpg";
import galeryAntihumidity9 from "../../../assets/images/antihumidity/galery/galeryAntihumidity9.jpg";
import galeryAntihumidity10 from "../../../assets/images/antihumidity/galery/galeryAntihumidity10.jpg";
import galeryAntihumidity11 from "../../../assets/images/antihumidity/galery/galeryAntihumidity11.jpg";
import galeryAntihumidity12 from "../../../assets/images/antihumidity/galery/galeryAntihumidity12.jpg";
import galeryAntihumidity13 from "../../../assets/images/antihumidity/galery/galeryAntihumidity13.jpg";
import galeryAntihumidity14 from "../../../assets/images/antihumidity/galery/galeryAntihumidity14.jpg";
import galeryAntihumidity15 from "../../../assets/images/antihumidity/galery/galeryAntihumidity15.jpg";
import galeryAntihumidity16 from "../../../assets/images/antihumidity/galery/galeryAntihumidity16.jpg";
import galeryAntihumidity17 from "../../../assets/images/antihumidity/galery/galeryAntihumidity17.jpg";

import workAntihumidity1 from "../../../assets/images/antihumidity/galery/works/workAntihumidity1.jpg";
import workAntihumidity2 from "../../../assets/images/antihumidity/galery/works/workAntihumidity2.jpg";
import workAntihumidity3 from "../../../assets/images/antihumidity/galery/works/workAntihumidity3.jpg";
import workAntihumidity4 from "../../../assets/images/antihumidity/galery/works/workAntihumidity4.jpg";
import workAntihumidity5 from "../../../assets/images/antihumidity/galery/works/workAntihumidity5.jpg";
import workAntihumidity6 from "../../../assets/images/antihumidity/galery/works/workAntihumidity6.jpg";
import workAntihumidity7 from "../../../assets/images/antihumidity/galery/works/workAntihumidity7.jpg";
import workAntihumidity8 from "../../../assets/images/antihumidity/galery/works/workAntihumidity8.jpg";
import workAntihumidity9 from "../../../assets/images/antihumidity/galery/works/workAntihumidity9.jpg";
import workAntihumidity10 from "../../../assets/images/antihumidity/galery/works/workAntihumidity10.jpg";
const photos = [
    {
      src: galeryAntihumidity1,
      width: 3,
      height: 2
    },
    {
      src: galeryAntihumidity2,
      width: 1,
      height: 1
    },
    {
      src: galeryAntihumidity3,
      width: 2,
      height: 3
    },
    {
      src: galeryAntihumidity4,
      width: 3,
      height: 3
    },
    {
      src: galeryAntihumidity5,
      width: 4,
      height: 3
    },
    {
      src: galeryAntihumidity6,
      width: 3,
      height: 2
    },
    {
      src: galeryAntihumidity7,
      width: 3,
      height: 3
    },
    {
      src: galeryAntihumidity8,
      width: 3,
      height: 2
    },
    {
      src: galeryAntihumidity9,
      width: 3,
      height: 2
    },
    {
      src: galeryAntihumidity10,
      width: 3,
      height: 2
    },
    {
      src: galeryAntihumidity11,
      width: 3,
      height: 2
    },
    {
      src: galeryAntihumidity12,
      width: 3,
      height: 2
    },
    {
      src: galeryAntihumidity13,
      width: 3,
      height: 3
    },
    {
      src: galeryAntihumidity14,
      width: 3,
      height: 2
    },
    {
      src: galeryAntihumidity15,
      width: 4,
      height: 3
    },
    {
      src: galeryAntihumidity16,
      width: 3,
      height: 3
    },
    {
      src: galeryAntihumidity17,
      width: 4,
      height: 3
    },
    {
      src: workAntihumidity1,
      width: 3,
      height: 3
    },
    {
      src: workAntihumidity2,
      width: 3,
      height: 3
    },
    {
      src: workAntihumidity3,
      width: 3,
      height: 3
    },
    {
      src: workAntihumidity4,
      width: 3,
      height: 3
    },
    {
      src: workAntihumidity5,
      width: 3,
      height: 3
    },
    {
      src: workAntihumidity6,
      width: 3,
      height: 3
    },
    {
      src: workAntihumidity7,
      width: 3,
      height: 3
    },
    {
      src: workAntihumidity8,
      width: 3,
      height: 3
    },
    {
      src: workAntihumidity9,
      width: 3,
      height: 3
    },
    {
      src: workAntihumidity10,
      width: 3,
      height: 3
    },
  ]
export default photos;