const data = [
  {
    title: 'Inicio',
    link: '/',
    showDesktop: true,
    index: 0,
  },
  {
    title: '¿Quiénes somos?',
    link: '/quienes-somos',
    showDesktop: false,
    index: 1,
  },
  {
    title: 'Placas antihumedad',
    link: '/placas-antihumedad',
    showDesktop: true,
    index: 2,
  },
  {
    title: 'PVC',
    link: '/pvc',
    showDesktop: true,
    index: 3,
  },
  {
    title: 'Pisos',
    link: '/pisos',
    showDesktop: true,
    index: 4,
  },
  {
    title: 'Otros productos',
    link: '/otros-productos',
    showDesktop: true,
    index: 5,
  },
  {
    title: 'Contacto',
    link: '/contacto',
    showDesktop: true,
    index: 6,
  },    
];

export default data;