import galeryVinyl1 from "../../../../assets/images/floors/vinyl/galery/galeryVinyl1.jpg";
import galeryVinyl2 from "../../../../assets/images/floors/vinyl/galery/galeryVinyl2.jpg";
import galeryVinyl3 from "../../../../assets/images/floors/vinyl/galery/galeryVinyl3.jpg";
import galeryVinyl4 from "../../../../assets/images/floors/vinyl/galery/galeryVinyl4.jpg";
import galeryVinyl5 from "../../../../assets/images/floors/vinyl/galery/galeryVinyl5.jpg";
import galeryVinyl6 from "../../../../assets/images/floors/vinyl/galery/galeryVinyl6.jpg";
import galeryVinyl7 from "../../../../assets/images/floors/vinyl/galery/galeryVinyl7.jpg";
import galeryVinyl8 from "../../../../assets/images/floors/vinyl/galery/galeryVinyl8.jpg";
import galeryVinyl9 from "../../../../assets/images/floors/vinyl/galery/galeryVinyl9.jpg";
import galeryVinyl10 from "../../../../assets/images/floors/vinyl/galery/galeryVinyl10.jpg";

const photos = [
    {
      src: galeryVinyl1,
      width: 3,
      height: 3
    },
    {
      src: galeryVinyl2,
      width: 3,
      height: 3
    },
    {
      src: galeryVinyl3,
      width: 3,
      height: 3
    },
    {
      src: galeryVinyl4,
      width: 4,
      height: 3
    },
    {
      src: galeryVinyl5,
      width: 3,
      height: 3
    },
    {
      src: galeryVinyl6,
      width: 4,
      height: 3
    },
    {
      src: galeryVinyl7,
      width: 3,
      height: 3
    },
    {
      src: galeryVinyl8,
      width: 4,
      height: 3
    },
    {
      src: galeryVinyl9,
      width: 3,
      height: 3
    },
    {
      src: galeryVinyl10,
      width: 4,
      height: 3
    }
  ];

export default photos;