import cielorrasosPvc from "../../../assets/images/pvc/cielorrasos.jpg";
import revestimientosPvc from "../../../assets/images/pvc/revestimientos.jpg";
import cortinasPvc from "../../../assets/images/pvc/cortinas.jpg";
import puertasPvc from "../../../assets/images/pvc/puertas.jpg";

const categories = [
  {
    image: cielorrasosPvc,
    url: "cielorrasos",
    title: "Cielorrasos PVC",
    description:
      "Cielorrasos tipo machimbre de PVC para techos a partir de la extrusión de PVC dando como resultado un producto de gran resistencia, practicidad y durabilidad.",
  },
  {
    image: revestimientosPvc,
    url: "revestimientos",
    title: "Revestimientos PVC",
    description: "Revestimientos tipo machimbre de PVC para paredes de interiores. Aptos para establecimientos que requieren condiciones extremas de higiene o cámaras frigoríficas.",
  },
  {
    image: cortinasPvc,
    url: "cortinas",
    title: "Cortinas PVC",
    description: "Fabricadas con PVC virgen, cubren una amplia gama de requerimientos de obra, combinando belleza y durabilidad y siendo libres de mantenimiento.",
  },
  {
    image: puertasPvc,
    url: "puertas-plegables",
    title: "Puertas Plegables PVC",
    description: "Recomendable para interiores, proporcionando practicidad de instalación y mantención. Se producen en diferentes anchos y se adaptan a cualquier espacio.",
  },
];

export default categories;
