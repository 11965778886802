import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//own
import "./styles.scss";
import deck from "../../../../assets/images/floors/deck/deck.jpg";
import modelosDeck from "./models";
import Container from "./../../../../containers/Container";
import MainTitle from "./../../../../packages/app-components/MainTitle";
import ListProductCard from "../../../../packages/app-components/ListProductCard";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
  SMALL_CONTENT
} from "../../../../packages/utils/constants";
import ImageSection from "../../../../packages/app-components/ImageSection";


const Deck = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("deck", `deck--${size}`)}>
        <ImageSection imageSrc={deck} imgAlt="Deck vinílico"/>
        <MainTitle>Conocé nuestros modelos de Deck vinílico</MainTitle>    
        <ListProductCard products={modelosDeck} sizeContent={SMALL_CONTENT}></ListProductCard>
      </div>
    </Container>
  );
};

export default Deck;
