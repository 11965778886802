// @ packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @ own
import './styles.scss';

const Heading = ({
  className,
  children,
  level,
  light,
  regular,
  bold,
  center,
  inlineBlock,
  inline,
  leading,
  trailing,
  noGutter,
  tabs,
  ...rest
}) => {
  const Tag = `h${level}`;
  return (
    <Tag
      className={cn(
        'heading',
        `heading--${level}`,
        { 'heading--light': light },
        { 'heading--regular': regular },
        { 'heading--bold': bold },
        { 'heading--center': center },
        { 'heading--inline-block': inlineBlock },
        { 'heading--inline': inline },
        { 'heading--leading': leading },
        { 'heading--trailing': trailing },
        { 'heading--no-gutter': noGutter },
        { 'heading--tabs': tabs },
        className,
      )}
      {...rest}
    >
      {children}
    </Tag>
  );
};

Heading.propTypes = {
  className: PropTypes.string,
  level: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']),
  children: PropTypes.node,
  light: PropTypes.bool,
  regular: PropTypes.bool,
  bold: PropTypes.bool,
  center: PropTypes.bool,
  inlineBlock: PropTypes.bool,
  inline: PropTypes.bool,
  leading: PropTypes.bool,
  trailing: PropTypes.bool,
  noGutter: PropTypes.bool,
  tabs: PropTypes.bool,
};

Heading.defaultProps = {
  className: '',
  children: '',
  light: false,
  regular: false,
  bold: false,
  center: false,
  inlineBlock: false,
  inline: false,
  leading: false,
  trailing: false,
  noGutter: false,
  tabs: false,
  level: '1',
};

export default Heading;
