// @ packages
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

// @ own
import "./styles.scss";

const NavbarMobileCustom = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Navbar expand="lg" fixed="top" variant="dark" className="navbar-mobile">
      <div href="#home" className="navbar-mobile__main-section">
        <div className="navbar-mobile__title">
          ALCAR BAHIA
        </div>
        <div className="navbar-mobile__subtitle">
          Sistemas de Construcción en Seco
        </div>
      </div>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        className="navbar-mobile__nav__toggler"
        onClick={() => handleMenuClick()}
      />
      {menuOpen && (
        <div className="navbar-mobile__panel">
          <Nav className="navbar-mobile__panel__links">
            {props.data.map(function (item, i) {
              return (
                <Link
                  key={i}
                  className="navbar-mobile__nav__link"
                  to={item.link}
                  onClick={() => handleMenuClick()}
                >
                  {item.title}
                </Link>
              );
            })}
          </Nav>
        </div>
      )}
    </Navbar>
  );
};

export default NavbarMobileCustom;
