import poleaGalvanizada from "../../../../assets/images/pvc/curtains/accesories/polea_galvanizada.jpg";
import ejeGalvanizado from "../../../../assets/images/pvc/curtains/accesories/eje_galvanizado.jpg";
import puntaEjeGalvanizado from "../../../../assets/images/pvc/curtains/accesories/puntas_de_eje.jpg";
import enrollador from "../../../../assets/images/pvc/curtains/accesories/enrollador.jpg";
import topePvc from "../../../../assets/images/pvc/curtains/accesories/tope_pvc.jpg";
import cinta from "../../../../assets/images/pvc/curtains/accesories/cinta.jpg";
import cajaMaderaEnrollador from "../../../../assets/images/pvc/curtains/accesories/caja_madera_enrollador.jpg";
import tacoMaderaEje from "../../../../assets/images/pvc/curtains/accesories/taco_madera_para_eje.jpg";
import grampa from "../../../../assets/images/pvc/curtains/accesories/grampa.jpg";

const accesories = [
    {
        image: poleaGalvanizada,
        alt: 'Poleas Galvanizadas', 
        title: 'Poleas Galvanizadas',
    },
    {
        image: ejeGalvanizado,
        alt: 'Ejes Galvanizados', 
        title: 'Ejes Galvanizados',
    },
    {
        image: puntaEjeGalvanizado,
        alt: 'Puntas de Eje Galvanizados', 
        title: 'Puntas de Eje Galvanizados',
    },
    {
        image: enrollador,
        alt: 'Enrollador', 
        title: 'Enrollador',
    },
    {
        image: grampa,
        alt: 'Grampas', 
        title: 'Grampas',
    },    
    {
        image: cinta,
        alt: 'Cinta', 
        title: 'Cinta',
    },
    {
        image: topePvc,
        alt: 'Tope de Pvc', 
        title: 'Tope de Pvc',
    },
    {
        image: cajaMaderaEnrollador,
        alt: 'Caja de Madera para Enrollador', 
        title: 'Caja de Madera para Enrollador', 
    },
    {
        image: tacoMaderaEje,
        alt: 'Taco Madera para Eje', 
        title: 'Taco Madera para Eje', 
    },
];

export default accesories;