// @package
import React, { useEffect, useState } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

//own
import { isValidUrl } from './packages/utils/helpers';

const ProtectedRoute = ({ history, component: WrappedComponent, ...rest }) => {
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (history.location.pathname !== '/' && !isValidUrl(history.location.pathname)) setIsValid(false);
  }, [history.location.pathname]);

  return (
    <Route
      {...rest}
      render={props => (
        isValid ? <WrappedComponent {...props} />
          : <Redirect to="/" />
      )}
    />
  );
};

ProtectedRoute.propTypes = {
    component: PropTypes.func.isRequired,
};

export default withRouter(ProtectedRoute);
