import deckPampa from "../../../../assets/images/floors/deck/models/deck_pampa.jpg";
import deckOna from "../../../../assets/images/floors/deck/models/deck_ona.jpg";
import deckToba from "../../../../assets/images/floors/deck/models/deck_toba.jpg";

const modelosDeck = [
    {
        alt: 'Pampa',
        image: deckPampa, 
        title: 'Pampa',        
    },
    {
        alt: 'Ona',
        image: deckOna, 
        title: 'Ona',        
    },
    {
        alt: 'Toba',
        image: deckToba, 
        title: 'Toba',        
    },     
];

export default modelosDeck;