import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCheck } from "@fortawesome/free-solid-svg-icons";

//own
import "./styles.scss";
import revestimientosPvc from "../../../../assets/images/pvc/revestimientos.jpg";
import coatingsAdvantages from "../../../../assets/images/pvc/coatings/coatings_advantages.jpg";
import advantages from "./advantages";
import modelosCielorrasosPvc from "./models";
import accesories from "./accesories";
import Container from "./../../../../containers/Container";
import MainTitle from "./../../../../packages/app-components/MainTitle";
import ImageSection from "../../../../packages/app-components/ImageSection";
import HorizontalCard from "./../../../../packages/base-components/HorizontalCard";
import ListProductCard from "../../../../packages/app-components/ListProductCard";
import galleryPhotos from "./galeryPhotos";
import Text from "./../../../../packages/base-components/Text";
import ResponsiveGallery from "../../../../packages/base-components/ResponsiveGallery";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
  EXTRA_LARGE_CONTENT, 
  SMALL_CONTENT
} from "../../../../packages/utils/constants";

const Coatings = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("coatings", `ceilings--${size}`)}>
        <ImageSection
          imageSrc={revestimientosPvc}
          imgAlt="Revestimientos PVC"
        />
        <MainTitle>Modelos de Revestimientos en PVC</MainTitle>
        <Text variant="body">
          {
            <div>
              Los cielorrasos de PVC son de facil instalación, libres de mantenimiento, impermeables, resistentes a innumerables agentes químicos, autoextinguibles y de agradable aspecto estético.
              <ul className="coatings__list">
                <li>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="coatings__list__icon"
                  />
                  Son la solución ideal para lograr excelentes terminaciones en
                  revestimientos de paredes en viviendas, industrias y
                  comercios.
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="coatings__list__icon"
                  />
                  Sus tablas de 200/250 mm y de 7/10 mm de espesor se encastran
                  de manera tal que ocultan los tornillos de sujeción, formando
                  una superficie lisa y brillante.
                </li>
              </ul>
            </div>
          }
        </Text>
        <ListProductCard products={modelosCielorrasosPvc} className="coatings__models" sizeContent={EXTRA_LARGE_CONTENT}></ListProductCard>

        <MainTitle>Accesorios</MainTitle>
        <ListProductCard products={accesories} sizeContent={SMALL_CONTENT}></ListProductCard>

        <MainTitle>Ventajas</MainTitle>
        <HorizontalCard
          description={
            <div>
              <ul className="advantages">
                {advantages.map((advantage, i) => {
                  return (
                    <li key={i}>
                      <FontAwesomeIcon icon={faCheckCircle} /> {advantage}
                    </li>
                  );
                })}
              </ul>
            </div>
          }
          imageAlt="Ventajas Revestimientos Pvc"
          imageSrc={coatingsAdvantages}
        />
        <MainTitle>Galería</MainTitle>
        <ResponsiveGallery photos={galleryPhotos} />
      </div>
    </Container>
  );
};

export default Coatings;
