import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//own
import logo from "../../../assets/images/logos/logo-horizontal-mini-.jpg";
import Container from "./../../../containers/Container";
import ImageSection from "../../../packages/app-components/ImageSection";
import MainTitle from "../../../packages/app-components/MainTitle";
import "./styles.scss";
import { history, goal } from "./history";
import galleryPhotos from "./galeryPhotos";
import ResponsiveGallery from "../../../packages/base-components/ResponsiveGallery";
import Text from "../../../packages/base-components/Text";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
} from "../../../packages/utils/constants";

const About = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("about", `about--${size}`)}>
        <ImageSection
          imageSrc={logo}
          imageAlt="ALCAR BAHÍA"
          className="about__image"
        />
        <Text variant="body" className="about__history">
          {history.p1}
        </Text>
        <Text variant="body" className="about__history">
          {history.p2}
        </Text>
        <Text variant="body" className="about__history">
          {history.p3}
        </Text>
        <Text variant="body" className="about__history">
          {history.p4}
        </Text>
        <Text className="about__goal">
          {goal}
        </Text>
        <MainTitle>Vení a visitarnos a nuestro local</MainTitle>
        <ResponsiveGallery photos={galleryPhotos} />
      </div>
    </Container>
  );
};

export default About;
