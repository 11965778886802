// @ packages
import React from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';


// @ own
import NavbarMobileCustom from './NavbarMobileCustom';
import TabsBarCustom from './TabsBarCustom';

import {
    MATCHES_MOBILE
  } from '../../packages/utils/constants';
import headerLinks from "./headerLinks";
import "./styles.scss";

const Header = () => {
    const matchesMobile = useMediaQuery(MATCHES_MOBILE);

    return matchesMobile ? (      
        <NavbarMobileCustom data={headerLinks} />
        ) : (
          <TabsBarCustom data={headerLinks} />
            );
};

export default Header;
