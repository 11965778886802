import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

//own
import "./styles.scss";
import modelosPlacasAntihumedad from "./models";
import accesories from "./accesories";
import itemsKit from "./kit";
import Container from "./../../../containers/Container";
import ListProductCard from "../../../packages/app-components/ListProductCard";
import imgLanding from "../../../assets/images/antihumidity/placas_antihumedad.jpg";
import img1 from "../../../assets/images/antihumidity/chau_humedad.jpg";
import img2 from "../../../assets/images/antihumidity/kit.jpg";
import antihumidityAdvantages from "../../../assets/images/antihumidity/antihumidity_advantages.jpg";
import advantages from "./advantages";
import MainTitle from "../../../packages/app-components/MainTitle";
import HorizontalCard from "../../../packages/base-components/HorizontalCard";
import Text from "../../../packages/base-components/Text";
import colocacionPlacasTauroc from "./../../../assets/images/antihumidity/colocacion_placas_tauroc.jpg";
import presupuestoColocacion from "./../../../assets/images/presupuesto_colocacion.jpg";
import palletRevendedor from "./../../../assets/images/antihumidity/pallet_revendedor.jpg";
import doc from "../../../assets/docs/manual_colocador.pdf";
import galleryPhotos from "./galeryPhotos";
import ResponsiveGallery from "../../../packages/base-components/ResponsiveGallery";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
  EXTRA_LARGE_CONTENT
} from "../../../packages/utils/constants";
import ImageSection from "../../../packages/app-components/ImageSection";

const Antihumidity = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("antihumidity", `antihumidity--${size}`)}>
        <ImageSection
          imageSrc={imgLanding}
          imgAlt="Placas Antihumedad Tauroc"
        />
        <MainTitle>Conocé nuestras placas antihumedad</MainTitle>
        <Text variant="body">
          Te ofrecemos la solución definitiva al problema de humedad en paredes
          y techos. Tenemos varios modelos, brindamos asesoramiento y servicio
          de colocación de placas antihumedad por personal especializado. Podrás
          renovar todo tipo de ambientes en muy poco tiempo.
        </Text>
        <div className="antihumidity__ads">
          <img
            src={img1}
            alt="Placas Antihumedad Tauroc"
            className="antihumidity__ads__ad"
          />
          <img
            src={img2}
            alt="Placas Antihumedad Tauroc"
            className="antihumidity__ads__ad"
          />
        </div>        
        <MainTitle>Modelos</MainTitle>
        <ListProductCard products={modelosPlacasAntihumedad}></ListProductCard>
        <MainTitle>Kit Hágalo usted mismo!</MainTitle>
        <Text variant="body">
          Nuestro kit cubre un total de 1,44m². Incluye 8 placas antihumedad de
          modelo chico, que miden 0,60 x 0,30 mts cada una. Sólo necesitarás llevar
          sellador y adhesivo para realizar la colocación de sus placas.
        </Text>
        <ListProductCard products={itemsKit} sizeContent={EXTRA_LARGE_CONTENT}></ListProductCard>
        <div className="antihumidity__video">
          <iframe
            className="antihumidity__video__iframe"
            title="Kit Placas Antihumedad"
            width="90%"
            height="350"
            src="https://www.youtube.com/embed/rjhLbAikn2k"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <MainTitle>Accesorios</MainTitle>
        <ListProductCard products={accesories}></ListProductCard>

        <MainTitle>
          ¿Querés conocer más sobre el proceso de colocación?
        </MainTitle>
        <HorizontalCard
          description="Te invitamos a leer nuestro instructivo de cómo colocar las placas antihumedad y cómo utilizar nuestro adhesivo y sella junta para realizar el trabajo completo."
          imageAlt="Colocación placas antihumedad"
          imageSrc={colocacionPlacasTauroc}
          textButton="Leer el manual de colocación"
          linkButton={doc}
        />

        <MainTitle>
          ¿Querés que te presupuestemos el servicio de colocación?
        </MainTitle>
        <HorizontalCard
          description="Si necesitás que te presupuestemos la provisión y colocación de nuestras placas antihumedad en manos de nuestro personal especializado, no dudes en consultarnos."
          imageAlt="Presupuesto colocación placas antihumedad"
          imageSrc={presupuestoColocacion}
          textButton="Solicitá tu presupuesto"
          linkButton="/contacto"
          navigatePage 
        />

        <MainTitle>¿Cómo funcionan nuestras Placas Antihumedad?</MainTitle>
        <HorizontalCard
          description={
            <div>
              <ul className="advantages">
                {advantages.map((advantage, i) => {
                  return (
                    <li key={i}>
                      <FontAwesomeIcon icon={faCheckCircle} /> {advantage}
                    </li>
                  );
                })}
              </ul>
            </div>
          }
          imageAlt="Ventajas Placas Antihumedad"
          imageSrc={antihumidityAdvantages}
        />

        <MainTitle>¿Te interesa ser revendedor?</MainTitle>
        <HorizontalCard
          description="Si estás interesado en ser revendedor de nuestras Placas Antihumedad, no dudes en llamarnos. Te comentaremos cuáles son las condiciones, cómo es el proceso y cómo sería la dinámica entre vos y nosotros para que puedas comercializar las Placas Antihumedad."
          imageAlt="Revendedor Placas Antihumedad"
          imageSrc={palletRevendedor}
        />
        <MainTitle>Galería</MainTitle>
        <ResponsiveGallery photos={galleryPhotos} />
      </div>
    </Container>
  );
};

export default Antihumidity;
