import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

//own
import "./styles.scss";
import pisosVinilicos from "../../../../assets/images/floors/vinyl/pisos-vinilicos.jpg";
import modelosVinylSistemaPegar from "./modelsSistemaPegar";
import modelosVinylSistemaEncastre from "./modelsSistemaEncastre";
import Container from "./../../../../containers/Container";
import MainTitle from "./../../../../packages/app-components/MainTitle";
import ImageSection from "./../../../../packages/app-components/ImageSection";
import ListProductCard from "../../../../packages/app-components/ListProductCard";
import galleryPhotos from "./galeryPhotos";
import ResponsiveGallery from "../../../../packages/base-components/ResponsiveGallery";
import Text from "../../../../packages/base-components/Text";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
  SMALL_CONTENT
} from "../../../../packages/utils/constants";

const Vinyl = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("vinyl", `vinyl--${size}`)}>
        <ImageSection
          imageSrc={pisosVinilicos}
          imgAlt="Pisos a prueba de agua"
        />
        <MainTitle>Modelos de sistema para pegar</MainTitle>
        <Text variant="body">
          {
            <div>
              Son pisos que están compuestos por material 100% de PVC y se
              utilizan en la instalación de suelos con un alto rendimiento.
              Estos pisos vinílicos tienen una gran resistencia al desgaste y
              son libres de mantenimiento, poseen el valor agregado de una
              instalación muy sencilla, a través del sistema de pegado. Están
              compuestos por:
              <ul className="vinyl__list">
                <li>
                <FontAwesomeIcon icon={faCheck} className="vinyl__list__icon"/> Una capa superficial que le
                  otorga resistencia a altas exigencias en el tránsito, evitando
                  su desgaste y facilitando su limpieza.
                </li>
                <li>
                <FontAwesomeIcon icon={faCheck} className="vinyl__list__icon"/> Un diseño decorativo
                </li>
                <li>
                <FontAwesomeIcon icon={faCheck} className="vinyl__list__icon"/> Y finalmente la estructura del
                  piso que es de PVC 100%.
                </li>
              </ul>
            </div>
          }
        </Text>
        <ListProductCard products={modelosVinylSistemaPegar} sizeContent={SMALL_CONTENT}></ListProductCard>

        <MainTitle>Modelos de sistema de encastre Clic</MainTitle>
        <Text variant="body">
          {
            <div>
              Son una combinación de piedra caliza, resinas y PVC que se utiliza
              en la instalación de suelos con un rendimiento altamente eficaz.
              Entre sus principales ventajas:
              <ul className="vinyl__list">
                <li>
                <FontAwesomeIcon icon={faCheck} className="coatings__list__icon"/> Son resistentes al agua, a las
                  rayaduras, a prueba de humedad.
                </li>
                <li>
                <FontAwesomeIcon icon={faCheck} className="coatings__list__icon"/> Son antideslizantes.
                </li>
                <li>
                <FontAwesomeIcon icon={faCheck} className="coatings__list__icon"/> Son de fácil mantenimiento, de
                  fácil limpieza, anti-bacterial y antialérgico.
                </li>
                <li>
                <FontAwesomeIcon icon={faCheck} className="coatings__list__icon"/> Cuentan con un sistema Clic de
                  fácil instalación.
                </li>
              </ul>
            </div>
          }
        </Text>
        <ListProductCard products={modelosVinylSistemaEncastre} sizeContent={SMALL_CONTENT}></ListProductCard>

        <MainTitle>Galería</MainTitle>
        <ResponsiveGallery photos={galleryPhotos} />
      </div>
    </Container>
  );
};

export default Vinyl;
