import Home from './../../apps/components/Home';

export const home = {
  path: ['/', '/inicio'],
  exact: 'true',
  component: Home,
};

export const defaultRoute = {
  path: '*',
  exact: 'true',
  component: Home,
};


