import { faWhatsapp, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const footerLinksDesktop = [
  {
    title: "Visitá nuestro local",
    icon: faMapMarkerAlt,
    link: "/contacto",
  },
  {
    title: "Escribinos por WhatsApp",
    icon: faWhatsapp,
    link: "https://api.whatsapp.com/send?phone=5492914634258&text=Hola ALCAR BAHIA!",
  },
  {
    title: "alcarbahia@yahoo.com.ar",
    icon: faEnvelope,
    link: "mailto:alcarbahia@yahoo.com.ar?subject=ALCAR%20BAHIA%20CLIENTE",
  },
  {
    title: "",
    icon: faInstagram,
    link: "https://www.instagram.com/alcarbahia",
  },
  {
    title: "",
    icon: faFacebook,
    link: "https://www.facebook.com/alcar.bahia",
  },
];

const footerLinksMobile = [
  {
    title: "Visitanos",
    icon: faMapMarkerAlt,
    link: "/contacto",
  },
  {
    title: "",
    icon: faInstagram,
    link: "https://www.instagram.com/alcarbahia",
  },
  {
    title: "",
    icon: faFacebook,
    link: "https://www.facebook.com/alcar.bahia",
  },
];

export {
  footerLinksDesktop,
  footerLinksMobile
};
