import zocalo1 from "../../../../assets/images/floors/baseboards/models/pvc/zocaloPvc1.jpg";
import zocalo2 from "../../../../assets/images/floors/baseboards/models/pvc/zocaloPvc2.jpg";
import zocalo3 from "../../../../assets/images/floors/baseboards/models/pvc/zocaloPvc3.jpg";


const modelosZocalosPVC = [
    {
        alt: 'PVC Blanco',
        image: zocalo1, 
        //price: '$601 por tira (contado)',
        title: 'PVC Blanco',
        items: [
            {
                value: 'Largo',
                description: '3000mm'
            },
        ]   
    },
    {
        alt: 'PVC Haya',
        image: zocalo2, 
        //price: '$601 por tira (contado)',
        title: 'PVC Haya',
        items: [
            {
                value: 'Largo',
                description: '3000mm'
            },
        ]   
    },
    {
        alt: 'PVC Cedro',
        image: zocalo3, 
        //price: '$601 por tira (contado)',
        title: 'PVC Cedro',
        items: [
            {
                value: 'Largo',
                description: '3000mm'
            },
        ]   
    },    
];

export default modelosZocalosPVC;