import robleNewYork from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo1.jpg";
import robleEstrasburgo from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo2.jpg";
import jatobaMatogroso from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo3.jpg";
import fresnoNordico from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo4.jpg";
import robleSantiago from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo5.jpg";
import robleNatural from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo6.jpg";
import robleOrleans from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo7.jpg";
import cavallo from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo8.jpg";
import cerezo from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo9.jpg";
import tekaPlank from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo10.jpg";
import chocolate from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo11.jpg";
import blancoPintable from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo12.jpg";
import pinoSacramento from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo13.jpg";
import haya from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo14.jpg";
import roble from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo15.jpg";
import merbau from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo16.jpg";
import nogalRojo from "../../../../assets/images/floors/baseboards/models/melaminicos/zocalo17.jpg";


const modelosZocalosMelaminicos = [
    {
        alt: 'Roble New York',
        image: robleNewYork, 
        //price: ' $466 por tira (contado)',
        title: 'Roble New York',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Roble Estrasburgo',
        image: robleEstrasburgo, 
        //price: ' $475 por tira (contado)',
        title: 'Roble Estrasburgo',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Jatoba Matogrosso',
        image: jatobaMatogroso, 
        //price: '$466 por tira (contado)',
        title: 'Jatoba Matogroso',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Fresno Nórdico',
        image: fresnoNordico, 
        //price: '$466 por tira (contado)',
        title: 'Fresno Nórdico',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Roble Santiago',
        image: robleSantiago, 
        //price: '$466 por tira (contado)',
        title: 'Roble Santiago',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Roble Natural',
        image: robleNatural, 
        //price: '$466 por tira (contado)',
        title: 'Roble Natural',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Roble Orleans',
        image: robleOrleans, 
        //price: '$466 por tira (contado)',
        title: 'Roble Orleans',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Cavallo',
        image: cavallo, 
        //price: '$466 por tira (contado)',
        title: 'Cavallo',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Cerezo',
        image: cerezo, 
        //price: '$466 por tira (contado)',
        title: 'Cerezo',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Teka Plank',
        image: tekaPlank, 
        //price: '$466 por tira (contado)',
        title: 'Teka Plank',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Chocolate',
        image: chocolate, 
        //price: '$466 por tira (contado)',
        title: 'Chocolate',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Blanco Pintable',
        image: blancoPintable, 
        //price: '$410 por tira (contado)',
        title: 'Blanco Pintable',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2750mm'
            },
        ]   
    },
    {
        alt: 'Pino Sacramento',
        image: pinoSacramento, 
        //price: '$466 por tira (contado)',
        title: 'Pino Sacramento',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Haya',
        image: haya, 
        //price: '$466 por tira (contado)',
        title: 'Haya',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Roble',
        image: roble, 
        //price: '$466 por tira (contado)',
        title: 'Roble',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Merbau',
        image: merbau, 
        //price: '$466 por tira (contado)',
        title: 'Merbau',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
    {
        alt: 'Nogal Rojo',
        image: nogalRojo, 
        //price: '$466 por tira (contado)',
        title: 'Nogal Rojo',
        items: [
            {
                value: 'Medida',
                description: '17mm x 58mm x 2400mm'
            },
        ]   
    },
];

export default modelosZocalosMelaminicos;