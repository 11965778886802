// @ packages
import React from "react";
import cn from "classnames";

// @ own
import "./styles.scss";
import Heading from "../../base-components/Heading";

const MainTitle = ({ className, children, ...rest }) => {
  return (
    <div>
      <hr />
      <Heading level="4" regular className={cn("main-title", className)}
        {...rest}
      >
          {children}
      </Heading>
      <hr />
    </div>
  );
};

export default MainTitle;
