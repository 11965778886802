const elements = [
 'Puntas de eje',
 'Taco de madera',
 'Ejes',
 'Poleas',
 'Cajas de madera para amurar',
 'Enrolladores',
 'Cintas, grampas y topes de PVC'
];

export default elements;