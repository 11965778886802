import React from "react";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag, faPalette, faDollarSign, faExclamationCircle, faCheck} from "@fortawesome/free-solid-svg-icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//own
import "./styles.scss";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
} from "../../../utils/constants";

const ProductCard = (props) => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <div className={cn("item-card", `item-card--${size}`, props.className)}>
      <img className="item-card__image" alt={props.alt} src={props.image} />
      <div className={cn("item-card__body", `item-card__body--${props.sizeContent}`)}>
        <div className="item-card__body__title">{props.title}</div>
        {props.items &&
          props.items.map(function (item) {
            return (
              item.value && (
                <div key={item.value} className="item-card__body__text">
                  {item.value}: {item.description}
                </div>
              )
            );
          })}
        {props.price && (
          <div>
            <hr />
            <span className="item-card__body__footer-text">
              <FontAwesomeIcon
                icon={faTag}
                className="item-card__body__icon"
              />
              {props.price}
            </span>
            {props.refColorOcre && (
              <div className="item-card__body__footer-color">
                <FontAwesomeIcon
                  icon={faPalette}
                  className="item-card__body__icon-color"
                />
                 Disponible Color Ocre
              </div>
            )}
          </div>
        )}
        {props.isOffer && (
          <div className="item-card__body__footer-offer">
          <FontAwesomeIcon
            icon={faDollarSign}
            className="item-card__body__icon"
          />
          OFERTA ESPECIAL {props.isOfferStock && 'HASTA AGOTAR STOCK'}
        </div>
        )}
        {!props.isAvailable && (
          <div className="item-card__body__footer-no-available">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="item-card__body__icon"
          />
          Stock: No disponible
        </div>
        )}
        {props.isKit && (
          <div>
          <span className="item-card__body__footer-is-kit">
            <FontAwesomeIcon
              icon={faCheck}
              className="item-card__body__icon"
            />
            Disponible en Kit
          </span>
        </div>
        )}
      </div>
    </div>
  );
};
export default ProductCard;

ProductCard.defaultProps = {
  sizeContent: 'large-content',
  isAvailable: true,
}
