/* Images Accesorios */
import perfilJ from "../../../../assets/images/pvc/coatings/accesories/perfilJ.jpg";
import perfilAnguloExterno from "../../../../assets/images/pvc/coatings/accesories/perfilAnguloExterno.jpg";
import perfilAnguloInterno from "../../../../assets/images/pvc/coatings/accesories/perfilAnguloInterno.jpg";
import zocaloSanitario from "../../../../assets/images/pvc/coatings/accesories/zocaloSanitarioMediaCaña.jpg";
import zocaloSanitarioJ from "../../../../assets/images/pvc/coatings/accesories/zocaloSanitarioJ.jpg";

const accesories = [
    {
        image: perfilJ,
        alt: 'Perfil J', 
        //price: '$896 (contado)',
        title: 'Perfil J',
        items: [
            {
                value: 'Colores',
                description: 'Blanco'
            },
            {
                value: 'Medida',
                description: '6 mts' 
            }
        ] 
    },
    {
        image: perfilAnguloExterno,
        alt: 'Perfil Ángulo Externo', 
        //price: '$242 (contado)',
        title: 'Perfil Ángulo Externo',
        items: [
            {
                value: 'Colores',
                description: 'Blanco'
            },
            {
                value: 'Medida',
                description: '3 mts' 
            }
        ] 
    },
    {
        image: perfilAnguloInterno,
        alt: 'Perfil Ángulo Interno', 
        //price: '$242 (contado)',
        title: 'Perfil Ángulo Interno',
        items: [
            {
                value: 'Colores',
                description: 'Blanco'
            },
            {
                value: 'Medida',
                description: '3 mts'
            }
        ] 
    },
    {
        image: zocaloSanitario,
        alt: 'Zócalo Sanitario 1/2 Caña', 
        //price: '$1270 (contado)',
        title: 'Zócalo Sanitario 1/2 Caña',
        items: [
            {
                value: 'Colores',
                description: 'Blanco'
            },
            {
                value: 'Medida',
                description: '3 mts'
            }
        ] 
    },
    {
        image: zocaloSanitarioJ,
        alt: 'Zócalo Sanitario J', 
        //price: '$670 (contado)',
        title: 'Zócalo Sanitario J',
        items: [
            {
                value: 'Colores',
                description: 'Blanco'
            },
            {
                value: 'Medida',
                description: '3 mts'
            }
        ] 
    },
];

export default accesories;