import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//own
import Container from "../../../containers/Container";
import HorizontalCard from "../../../packages/base-components/HorizontalCard";
import ResponsiveCarousel from "./ResponsiveCarousel";
import "./styles.scss";
import galleryPhotos from "./galeryPhotos";
import us from "../../../assets/images/about/galery/galeryAbout2.jpg";
import MainTitle from "../../../packages/app-components/MainTitle";
import ResponsiveGallery from "../../../packages/base-components/ResponsiveGallery";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
} from "../../../packages/utils/constants";

const Home = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("home", `home--${size}`)}>
        <ResponsiveCarousel className="home__carousel" />
        <MainTitle>Conocé nuestros principales productos</MainTitle>        
        <ResponsiveGallery photos={galleryPhotos} /> 
        <HorizontalCard
          title="¿Quiénes somos?"
          description="Te invitamos a leer sobre nosotros y conocer nuestra trayectoria."
          imageAlt="ALCAR BAHÍA"
          imageSrc={us}
          textButton="Conoce más sobre ALCAR BAHÍA"
          linkButton="/quienes-somos"
          navigatePage 
          className="home__horizontal-card-empresa"
        />
      </div>
    </Container>
  );
};

export default Home;
