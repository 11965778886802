import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

//own
import "./styles.scss";
import pisosFlotantes from "../../../../assets/images/floors/floating/pisos-flotantes.jpg";
import modelosPisosFlotantes7mm from "./models7mm";
import modelosPisosFlotantes8mm from "./models8mm";
import Container from "./../../../../containers/Container";
import MainTitle from "./../../../../packages/app-components/MainTitle";
import ImageSection from "../../../../packages/app-components/ImageSection";
import ListProductCard from "../../../../packages/app-components/ListProductCard";
import galleryPhotos from "./galeryPhotos";
import ResponsiveGallery from "../../../../packages/base-components/ResponsiveGallery";
import Text from "./../../../../packages/base-components/Text";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
  SMALL_CONTENT,
} from "../../../../packages/utils/constants";

const Floating = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("floating", `floating--${size}`)}>
        <ImageSection imageSrc={pisosFlotantes} imgAlt="Pisos Flotantes" />
        <MainTitle>Conocé nuestros modelos de Pisos Flotantes</MainTitle>
        <Text variant="body">
          {
            <div>
              Los pisos flotantes melamínicos son una elección indiscutida a la
              hora de pensar en pisos. Entre sus ventajas principales:
              <ul className="floating__list">
                <li>
                  <FontAwesomeIcon icon={faCheck} className="floating__list__icon"/> Calidez de sus diseños de
                  madera.
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} className="floating__list__icon" /> Amplio abanico de
                  tonalidades.
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} className="floating__list__icon" /> Gran resistencia.
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} className="floating__list__icon" /> Diseño decorativo con
                  motivos de madera de estilo natural.
                </li>
              </ul>
            </div>
          }
        </Text>

        <MainTitle>Línea SwissFloor 7mm</MainTitle>
        <ListProductCard products={modelosPisosFlotantes7mm} sizeContent={SMALL_CONTENT}></ListProductCard>

        <MainTitle>Línea SwissFloor 8mm</MainTitle>
        <ListProductCard products={modelosPisosFlotantes8mm} sizeContent={SMALL_CONTENT}></ListProductCard>

        <MainTitle>Galería</MainTitle>
        <ResponsiveGallery photos={galleryPhotos} />
      </div>
    </Container>
  );
};

export default Floating;
