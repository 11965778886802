import React from 'react';

//own
import Container from "./../../../containers/Container";
import ListSquareCard from '../../../packages/base-components/ListSquareCard';
import categoriesFloors from './categories';
import './styles.scss';

const Floors = () => 
        (
            <Container>
               <ListSquareCard section='pisos' categories={categoriesFloors} />
            </Container>
        );

export default Floors;