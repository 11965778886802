import robleOriginal from "../../../../assets/images/floors/floating/models/7mm/roble_original.jpg";
import robleWeiss from "../../../../assets/images/floors/floating/models/7mm/roble_weiss.jpg";
//import cerezoMonte from "../../../../assets/images/floors/floating/models/7mm/cerezo_monte.jpg";
//import pinoCoffee from "../../../../assets/images/floors/floating/models/7mm/pino_coffee.jpg";
import bordeaux from "../../../../assets/images/floors/floating/models/7mm/bordeaux.jpg";
import haya from "../../../../assets/images/floors/floating/models/7mm/haya.jpg";
import robleAmarone from "../../../../assets/images/floors/floating/models/7mm/roble_amarone.jpg";
import merbau from "../../../../assets/images/floors/floating/models/7mm/merbau.jpg";
//import robleSantiago from "../../../../assets/images/floors/floating/models/7mm/roble_santiago.jpg";
import robleCal from "../../../../assets/images/floors/floating/models/7mm/roble_cal.jpg";
import nogalRojo from "../../../../assets/images/floors/floating/models/7mm/nogal_rojo.jpg";

const modelosCielorrasosPvc = [
    {
        alt: 'Roble Original AC3',
        image: robleOriginal, 
        //price: 'm²: US$ 20,21 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Original AC3',
        items: [
            {
                value: 'Espesor',
                description: '7mm'
            },
        ]   
    },
    {
        alt: 'Roble Weiss AC3',
        image: robleWeiss, 
        //price: 'm²: US$ 20,21 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Weiss AC3',
        items: [
            {
                value: 'Espesor',
                description: '7mm'
            },
        ]   
    },
    /*{
        alt: 'Cerezo Monte AC3',
        image: cerezoMonte, 
        price: 'm²: US$ 20,21 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Cerezo Monte AC3',
        items: [
            {
                value: 'Espesor',
                description: '7mm'
            },
        ]   
    },
    {
        alt: 'Pino Coffee Original AC3',
        image: pinoCoffee, 
        price: 'm²: US$ 20,21 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Pino Coffee AC3',
        items: [
            {
                value: 'Espesor',
                description: '7mm'
            },
        ]   
    },*/
    {
        alt: 'Roble Bordeaux AC4',
        image: bordeaux, 
        //price: 'm²: US$ 22,32 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Bordeaux AC4',
        items: [
            {
                value: 'Espesor',
                description: '7mm'
            },
        ]   
    },
    {
        alt: 'Haya AC4',
        image: haya, 
        //price: 'm²: US$ 22,32 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Haya AC4',
        items: [
            {
                value: 'Espesor',
                description: '7mm'
            },
        ]   
    },
    {
        alt: 'Roble Amarone AC4',
        image: robleAmarone, 
        //price: 'm²: US$ 22,32 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Amarone AC4',
        items: [
            {
                value: 'Espesor',
                description: '7mm'
            },
        ]   
    },
    {
        alt: 'Merbau AC4',
        image: merbau, 
        //price: 'm²: US$ 22,32 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Merbau AC4',
        items: [
            {
                value: 'Espesor',
                description: '7mm'
            },
        ]   
    },
    /*{
        alt: 'Roble Santiago AC4',
        image: robleSantiago, 
        price: 'm²: US$ 22,32 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Santiago AC4',
        items: [
            {
                value: 'Espesor',
                description: '7mm'
            },
        ]   
    },*/
    {
        alt: 'Roble Cal AC4',
        image: robleCal, 
        //price: 'm²: US$ 22,32 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Roble Cal AC4',
        items: [
            {
                value: 'Espesor',
                description: '7mm'
            },
        ]   
    },
    {
        alt: 'Nogal Rojo',
        image: nogalRojo, 
        //price: 'm²: US$ 22,32 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'Nogal Rojo',
        items: [
            {
                value: 'Espesor',
                description: '7mm'
            },
        ]   
    },
];

export default modelosCielorrasosPvc;