import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//own
import "./styles.scss";
import zocalos from "../../../../assets/images/floors/baseboards/zocalos.jpg";
import modelosZocalosMelaminicos from "./modelsMelaminicos";
import modelosZocalosEPS from "./modelsEPS";
import modelosZocalosPVC from "./modelsPVC";
import modelosZocalosPVCTipoJ from "./modelsPVCTipoJ";
import accesoriosZocalos from "./accesories";
import Container from "../../../../containers/Container";
import MainTitle from "../../../../packages/app-components/MainTitle";
import ImageSection from "../../../../packages/app-components/ImageSection";
import ListProductCard from "../../../../packages/app-components/ListProductCard";
import galleryPhotos from "./galeryPhotos";
import ResponsiveGallery from "../../../../packages/base-components/ResponsiveGallery";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,  
  SMALL_CONTENT,
  MEDIUM_CONTENT,
} from "../../../../packages/utils/constants";


const Baseboards = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("baseboards", `accesories--${size}`)}>
        <ImageSection imageSrc={zocalos} imgAlt="Zócalos y accesorios" />

        <MainTitle>Modelos Zócalos de Melamina</MainTitle>    
        <ListProductCard products={modelosZocalosMelaminicos} sizeContent={SMALL_CONTENT}></ListProductCard>

        <MainTitle>Modelos Zócalos PVC</MainTitle>    
        <ListProductCard products={modelosZocalosPVC} sizeContent={SMALL_CONTENT}></ListProductCard>

        <MainTitle>Modelos Zócalos PVC Tipo J</MainTitle>    
        <ListProductCard products={modelosZocalosPVCTipoJ} sizeContent={SMALL_CONTENT}></ListProductCard>

        <MainTitle>Modelos Zócalos EPS</MainTitle>    
        <ListProductCard products={modelosZocalosEPS} sizeContent={SMALL_CONTENT}></ListProductCard>

        <MainTitle>Accesorios</MainTitle>    
        <ListProductCard products={accesoriosZocalos} sizeContent={MEDIUM_CONTENT}></ListProductCard>

        <MainTitle>Galería</MainTitle>
        <ResponsiveGallery photos={galleryPhotos} />
      </div>
    </Container>
  );
};

export default Baseboards;
