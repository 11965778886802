import sistemaPegar1 from "../../../../assets/images/floors/vinyl/models/sistema-pegar/sistemaPegar1.jpg";
import sistemaPegar2 from "../../../../assets/images/floors/vinyl/models/sistema-pegar/sistemaPegar2.jpg";
import sistemaPegar3 from "../../../../assets/images/floors/vinyl/models/sistema-pegar/sistemaPegar3.jpg";
import sistemaPegar4 from "../../../../assets/images/floors/vinyl/models/sistema-pegar/sistemaPegar4.jpg";
import sistemaPegar5 from "../../../../assets/images/floors/vinyl/models/sistema-pegar/sistemaPegar5.jpg";
import sistemaPegar6 from "../../../../assets/images/floors/vinyl/models/sistema-pegar/sistemaPegar6.jpg";
import sistemaPegar8 from "../../../../assets/images/floors/vinyl/models/sistema-pegar/sistemaPegar8.jpg";
import sistemaPegar9 from "../../../../assets/images/floors/vinyl/models/sistema-pegar/sistemaPegar9.jpg";
import sistemaPegar10 from "../../../../assets/images/floors/vinyl/models/sistema-pegar/sistemaPegar10.jpg";
import sistemaPegar11 from "../../../../assets/images/floors/vinyl/models/sistema-pegar/sistemaPegar11.jpg";
import sistemaPegar12 from "../../../../assets/images/floors/vinyl/models/sistema-pegar/sistemaPegar12.jpg";
import sistemaPegar13 from "../../../../assets/images/floors/vinyl/models/sistema-pegar/sistemaPegar13.jpg";
import sistemaPegar14 from "../../../../assets/images/floors/vinyl/models/sistema-pegar/sistemaPegar14.jpg";

const modelosVinylSistemaPegar = [
    {
        alt: 'KW 6046',
        image: sistemaPegar1, 
        //price: 'm²: US$ 16,26 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KW 6046',
        items: [
            {
                value: 'Espesor',
                description: '2 mm'
            },
        ]   
    },
    
    {
        alt: 'KW 6154',
        image: sistemaPegar2, 
        //price: 'm²: US$ 16,26 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KW 6154',
        items: [
            {
                value: 'Espesor',
                description: '2mm'
            },
        ]   
    },
    {
        alt: 'KW 6241',
        image: sistemaPegar3, 
        //price: 'm²: US$ 16,26 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KW 6241',
        items: [
            {
                value: 'Espesor',
                description: '2mm'
            },
        ]   
    },
    {
        alt: 'KW 7141',
        image: sistemaPegar4, 
        //price: 'm²: US$ 16,26 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KW 7141',
        items: [
            {
                value: 'Espesor',
                description: '2mm'
            },
        ]   
    },
    {
        alt: 'KW 6046',
        image: sistemaPegar1, 
        //price: 'm²: US$ 22,07 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KW 6046',
        items: [
            {
                value: 'Espesor',
                description: '3 mm'
            },
        ]   
    },
    {
        alt: 'KCT 2223',
        image: sistemaPegar5, 
        //price: 'm²: US$ 22,07 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KCT 2223',
        items: [
            {
                value: 'Espesor',
                description: '3mm'
            },
        ]   
    },
    {
        alt: 'KCT 3631',
        image: sistemaPegar6, 
        //price: 'm²: US$ 22,07 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KCT 3631',
        items: [
            {
                value: 'Espesor',
                description: '3mm'
            },
        ]   
    },
    {
        alt: 'KW 6141',
        image: sistemaPegar8, 
        //price: 'm²: US$ 22,07 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KW 6141',
        items: [
            {
                value: 'Espesor',
                description: '3mm'
            },
        ]   
    },
    {
        alt: 'KW 6151',
        image: sistemaPegar9, 
        //price: 'm²: US$ 22,07 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KW 6151',
        items: [
            {
                value: 'Espesor',
                description: '3mm'
            },
        ]   
    },
    {
        alt: 'KW 6153',
        image: sistemaPegar10, 
        //price: 'm²: US$ 22,07 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KW 6153',
        items: [
            {
                value: 'Espesor',
                description: '3mm'
            },
        ]   
    },
    {
        alt: 'KW 6311',
        image: sistemaPegar11, 
        //price: 'm²: US$ 22,07 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KW 6311',
        items: [
            {
                value: 'Espesor',
                description: '3mm'
            },
        ]   
    },
    {
        alt: 'KW 6314',
        image: sistemaPegar12, 
        //price: 'm²: US$ 22,07 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KW 6314',
        items: [
            {
                value: 'Espesor',
                description: '3mm'
            },
        ]   
    },
    {
        alt: 'KW 6731',
        image: sistemaPegar13, 
        //price: 'm²: US$ 22,07 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KW 6731',
        items: [
            {
                value: 'Espesor',
                description: '3mm'
            },
        ]   
    },
    {
        alt: 'KW 6781',
        image: sistemaPegar14, 
        //price: 'm²: US$ 22,07 (Dolar Bco Nacion Vendedor) (contado)',
        title: 'KW 6781',
        items: [
            {
                value: 'Espesor',
                description: '3mm'
            },
        ]   
    },
];

export default modelosVinylSistemaPegar;