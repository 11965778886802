import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//own
import "./styles.scss";
import offers from "../../../../assets/images/floors/offers/ofertas_pisos.jpg";
import modelosOffers from "./models";
import Container from "../../../../containers/Container";
import MainTitle from "../../../../packages/app-components/MainTitle";
import ListProductCard from "../../../../packages/app-components/ListProductCard";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
  MEDIUM_CONTENT
} from "../../../../packages/utils/constants";
import ImageSection from "../../../../packages/app-components/ImageSection";


const FloorsOffers = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("floors-offers", `floors-offers--${size}`)}>
        <ImageSection imageSrc={offers} imgAlt="Ofertas lotes discontinuos"/>
        <MainTitle>Aprovechá nuestras ofertas de pisos hasta agotar stock</MainTitle>    
        <ListProductCard products={modelosOffers} sizeContent={MEDIUM_CONTENT}></ListProductCard>
      </div>
    </Container>
  );
};

export default FloorsOffers;
