import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from 'classnames';

//own
import "./styles.scss";

const TabsBarCustom = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const updateSelectedTab = (tab) => {
    setSelectedTab(tab);
  }

  return (
    <div className="tabs-bar-custom">
      {props.data.map(function (item, i) {
        return (
          item.showDesktop && (
            <Link
              key={i}
              label={item.title}
              to={item.link}
              className={cn(
                'tabs-bar-custom__tab',
                {
                  'tabs-bar-custom__tab--selected': item.index === selectedTab,
                }
              )}
              onClick={() => updateSelectedTab(item.index)}
            >
              {item.title}
            </Link>
          )
        );
      })}
    </div>
  );
};

export default TabsBarCustom;
