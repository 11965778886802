import galeryAbout1 from "../../../assets/images/about/galery/galeryAbout1.jpg";
import galeryAbout2 from "../../../assets/images/about/galery/galeryAbout2.jpg";
import galeryAbout3 from "../../../assets/images/about/galery/galeryAbout3.jpg";
import galeryAbout4 from "../../../assets/images/about/galery/galeryAbout4.jpg";
import galeryAbout5 from "../../../assets/images/about/galery/galeryAbout5.jpg";
import galeryAbout6 from "../../../assets/images/about/galery/galeryAbout6.jpg";
import galeryAbout7 from "../../../assets/images/about/galery/galeryAbout7.jpg";
import galeryAbout8 from "../../../assets/images/about/galery/galeryAbout8.jpg";
import galeryAbout9 from "../../../assets/images/about/galery/galeryAbout9.jpg";
import galeryAbout10 from "../../../assets/images/about/galery/galeryAbout10.jpg";
import galeryAbout11 from "../../../assets/images/about/galery/galeryAbout11.jpg";
import galeryAbout12 from "../../../assets/images/about/galery/galeryAbout12.jpg";


const photos = [
    {
      src: galeryAbout1,
      width: 4,
      height: 3
    },    
    {
      src: galeryAbout3,
      width: 4,
      height: 3
    },
    {
      src: galeryAbout2,
      width: 4,
      height: 2
    },
    {
      src: galeryAbout4,
      width: 4,
      height: 3
    },
    {
      src: galeryAbout5,
      width: 1,
      height: 1
    },
    {
      src: galeryAbout6,
      width: 1,
      height: 1
    },
    {
      src: galeryAbout7,
      width: 1,
      height: 1
    },
    {
      src: galeryAbout8,
      width: 4,
      height: 3
    },
    {
      src: galeryAbout9,
      width: 1,
      height: 1
    },
    {
      src: galeryAbout10,
      width: 1,
      height: 1
    },
    {
      src: galeryAbout11,
      width: 1,
      height: 1
    },
    {
      src: galeryAbout12,
      width: 1,
      height: 1
    },
  ];

export default photos;