import { home, defaultRoute } from "./Home";
import about from "./About";
import contact from "./Contact";
import antihumidity from "./Antihumidity";
import pvc, {
  PvcCeilingsRoute as ceilingsPvc,
  PvcCoatingsRoute as coatingsPvc,
  PvcCurtainsRoute as curtainsPvc,
  PvcDoorsRoute as doorsPvc,
} from "./Pvc";

import floors, {
  FloorsFloatingRoute as floatingFloors,
  FloorsVinylRoute as vinylFloors,
  FloorsOffersRoute as offersFloors,
  FloorsBaseboardsRoute as baseboardsFloors,
  SyntheticGrassRoute as syntheticGrass,
  DeckRoute as deck,
} from "./Floors";
import drywall from "./Drywall";

const routes = [
  home,
  about,
  contact,
  antihumidity,
  floors,
  floatingFloors,
  vinylFloors,
  offersFloors,
  baseboardsFloors,
  syntheticGrass,
  deck,
  pvc,
  ceilingsPvc,
  coatingsPvc,
  curtainsPvc,
  doorsPvc,
  drywall,
  defaultRoute,  
];

export default routes;
