import puertaPvcBlanca from "../../../../assets/images/pvc/doors/models/puerta_blanca.jpg";
import puertaPvcGris from "../../../../assets/images/pvc/doors/models/puerta_gris.jpg";
import puertaPvcOcre from "../../../../assets/images/pvc/doors/models/puerta_ocre.jpg";

const modelosCortinasPvc = [
    {
        alt: 'Puerta Plegadiza Blanca',
        image: puertaPvcBlanca, 
        title: 'Puerta Plegadiza Blanca',
        items: [
            {
                value: 'Alto',
                description: '210 mm'
            },
            {
                value: 'Ancho',
                description: '72 / 82 / 92 / 102 / 112 mm'
            },
        ]   
    },
    {
        alt: 'Puerta Plegadiza Gris',
        image: puertaPvcGris, 
        title: 'Puerta Plegadiza Gris',
        isAvailable: false,
        items: [
            {
                value: 'Alto',
                description: '210 mm'
            },
            {
                value: 'Ancho',
                description: '72 / 82 / 92 / 102 / 112 mm'
            },
        ]   
    },
    {
        alt: 'Puerta Plegadiza Ocre',
        image: puertaPvcOcre, 
        title: 'Puerta Plegadiza Ocre',
        isAvailable: false,
        items: [
            {
                value: 'Alto',
                description: '210 mm'
            },
            {
                value: 'Ancho',
                description: '72 / 82 / 92 / 102 / 112 mm'
            },
        ]   
    },
];

export default modelosCortinasPvc;