import galeryCeilings1 from "../../../../assets/images/pvc/ceilings/galery/galeryCeilings1.jpg";
import galeryCeilings2 from "../../../../assets/images/pvc/ceilings/galery/galeryCeilings2.jpg";
import galeryCeilings3 from "../../../../assets/images/pvc/ceilings/galery/galeryCeilings3.jpg";
import galeryCeilings4 from "../../../../assets/images/pvc/ceilings/galery/galeryCeilings4.jpg";
import galeryCeilings5 from "../../../../assets/images/pvc/ceilings/galery/galeryCeilings5.jpg";
import galeryCeilings6 from "../../../../assets/images/pvc/ceilings/galery/galeryCeilings6.jpg";
import galeryCeilings7 from "../../../../assets/images/pvc/ceilings/galery/galeryCeilings7.jpg";

//works
import workPvcCeilings1 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings1.jpg";
import workPvcCeilings2 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings2.jpg";
import workPvcCeilings3 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings3.jpg";
import workPvcCeilings4 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings4.jpg";
import workPvcCeilings5 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings5.jpg";
import workPvcCeilings6 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings6.jpg";
import workPvcCeilings7 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings7.jpg";
import workPvcCeilings8 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings8.jpg";
import workPvcCeilings9 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings9.jpg";
import workPvcCeilings10 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings10.jpg";
import workPvcCeilings11 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings11.jpg";
import workPvcCeilings12 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings12.jpg";
import workPvcCeilings13 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings13.jpg";
import workPvcCeilings14 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings14.jpg";
import workPvcCeilings15 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings15.jpg";
import workPvcCeilings16 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings16.jpg";
import workPvcCeilings17 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings17.jpg";
import workPvcCeilings18 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings18.jpg";
import workPvcCeilings19 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings19.jpg";
import workPvcCeilings20 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings20.jpg";
import workPvcCeilings21 from "../../../../assets/images/pvc/ceilings/galery/works/workPvcCeilings21.jpg";

const photos = [
    {
      src: galeryCeilings1,
      width: 4,
      height: 3
    },
    {
      src: galeryCeilings2,
      width: 4,
      height: 3
    },
    {
      src: galeryCeilings3,
      width: 4,
      height: 3
    },
    {
      src: galeryCeilings4,
      width: 4,
      height: 3
    },
    {
      src: galeryCeilings5,
      width: 4,
      height: 3
    },   
    {
      src: galeryCeilings6,
      width: 4,
      height: 3
    },   
    {
      src: galeryCeilings7,
      width: 4,
      height: 3
    },   
    {
      src: workPvcCeilings1,
      width: 3,
      height: 3
    },   
    {
      src: workPvcCeilings2,
      width: 3,
      height: 3
    },   
    {
      src: workPvcCeilings3,
      width: 3,
      height: 3
    },   
    {
      src: workPvcCeilings4,
      width: 3,
      height: 3
    },   
    {
      src: workPvcCeilings5,
      width: 4,
      height: 3
    },   
    {
      src: workPvcCeilings6,
      width: 4,
      height: 3
    },   
    {
      src: workPvcCeilings7,
      width: 4,
      height: 3
    },   
    {
      src: workPvcCeilings8,
      width: 3,
      height: 3
    },   
    {
      src: workPvcCeilings9,
      width: 3,
      height: 3
    },   
    {
      src: workPvcCeilings10,
      width: 3,
      height: 3
    },   
    {
      src: workPvcCeilings11,
      width: 3,
      height: 3
    },   
    {
      src: workPvcCeilings12,
      width: 3,
      height: 3
    },   
    {
      src: workPvcCeilings13,
      width: 3,
      height: 3
    },   
    {
      src: workPvcCeilings14,
      width: 3,
      height: 3
    },   
    {
      src: workPvcCeilings15,
      width: 1,
      height: 1
    },    
    {
      src: workPvcCeilings16,
      width: 1,
      height: 1
    },   
    {
      src: workPvcCeilings17,
      width: 3,
      height: 3
    },   
    {
      src: workPvcCeilings18,
      width: 4,
      height: 3
    },   
    {
      src: workPvcCeilings19,
      width: 4,
      height: 3
    },   
    {
      src: workPvcCeilings20,
      width: 4,
      height: 3
    },   
    {
      src: workPvcCeilings21,
      width: 4,
      height: 3
    },   
  ];

export default photos;