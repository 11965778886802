import zocalo1 from "../../../../assets/images/floors/baseboards/models/eps/plain.jpg";
import zocalo2 from "../../../../assets/images/floors/baseboards/models/eps/curves.jpg";
import zocalo3 from "../../../../assets/images/floors/baseboards/models/eps/line.jpg";
import zocalo4 from "../../../../assets/images/floors/baseboards/models/eps/extraline.jpg";
import zocalo5 from "../../../../assets/images/floors/baseboards/models/eps/line_canela.jpg";
import zocalo6 from "../../../../assets/images/floors/baseboards/models/eps/line_cacao.jpg";
import zocalo7 from "../../../../assets/images/floors/baseboards/models/eps/line_gris_claro.jpg";
import zocalo8 from "../../../../assets/images/floors/baseboards/models/eps/curves_ambar.jpg";
import zocalo9 from "../../../../assets/images/floors/baseboards/models/eps/curves_avellana.jpg";
import zocalo10 from "../../../../assets/images/floors/baseboards/models/eps/curves_canela.jpg";
import zocalo11 from "../../../../assets/images/floors/baseboards/models/eps/curves_cacao.jpg";
import zocalo12 from "../../../../assets/images/floors/baseboards/models/eps/curves_gris_claro.jpg";


const modelosZocalosEPS = [
    {
        alt: 'Plain Blanco',
        image: zocalo1, 
        //price: '$702 por tira (contado)',
        title: 'Plain Blanco',
        items: [
            {
                value: 'Medida',
                description: '70mm x 2500mm'
            },
        ]   
    },    
    {
        alt: 'Line Blanco',
        image: zocalo3, 
        //price: '$702 por tira (contado)',
        title: 'Line Blanco',
        items: [
            {
                value: 'Medida',
                description: '70mm x 2500mm'
            },
        ]   
    },
    
    {
        alt: 'Line Canela',
        image: zocalo5, 
        //price: '$702 por tira (contado)',
        title: 'Line Canela',
        items: [
            {
                value: 'Medida',
                description: '70mm x 2500mm'
            },
        ]   
    },
    {
        alt: 'Line Cacao',
        image: zocalo6, 
        //price: '$702 por tira (contado)',
        title: 'Line Cacao',
        items: [
            {
                value: 'Medida',
                description: '70mm x 2500mm'
            },
        ]   
    },
    {
        alt: 'Line Gris Claro',
        image: zocalo7, 
        //price: '$702 por tira (contado)',
        title: 'Line Gris Claro',
        items: [
            {
                value: 'Medida',
                description: '70mm x 2500mm'
            },
        ]   
    },
    {
        alt: 'Curves Blanco',
        image: zocalo2, 
        //price: '$775 por tira (contado)',
        title: 'Curves Blanco',
        items: [
            {
                value: 'Medida',
                description: '80mm x 2500mm'
            },
        ]   
    },
    {
        alt: 'Curves Ambar',
        image: zocalo8, 
        //price: '$775 por tira (contado)',
        title: 'Curves Ambar',
        items: [
            {
                value: 'Medida',
                description: '80mm x 2500mm'
            },
        ]   
    },
    {
        alt: 'Curves Avellana',
        image: zocalo9, 
        //price: '$775 por tira (contado)',
        title: 'Curves Avellana',
        items: [
            {
                value: 'Medida',
                description: '80mm x 2500mm'
            },
        ]   
    },
    {
        alt: 'Curves Canela',
        image: zocalo10, 
        //price: '$775 por tira (contado)',
        title: 'Curves Canela',
        items: [
            {
                value: 'Medida',
                description: '80mm x 2500mm'
            },
        ]   
    },
    {
        alt: 'Curves Cacao',
        image: zocalo11, 
        //price: '$775 por tira (contado)',
        title: 'Curves Cacao',
        items: [
            {
                value: 'Medida',
                description: '80mm x 2500mm'
            },
        ]   
    },
    {
        alt: 'Curves Gris Claro',
        image: zocalo12, 
        //price: '$775 por tira (contado)',
        title: 'Curves Gris Claro',
        items: [
            {
                value: 'Medida',
                description: '80mm x 2500mm'
            },
        ]   
    },
    {
        alt: 'Extraline',
        image: zocalo4, 
        //price: '$1076 por tira (contado)',
        title: 'Extraline',
        items: [
            {
                value: 'Medida',
                description: '100mm x 2500mm'
            },
        ]   
    },
];

export default modelosZocalosEPS;