import galeryHome1 from "../../../assets/images/antihumidity/galery/galeryAntihumidity1.jpg";
import galeryHome2 from "../../../assets/images/antihumidity/galery/galeryAntihumidity2.jpg";
import galeryHome3 from "../../../assets/images/antihumidity/chau_humedad.jpg";
import galeryHome4 from "../../../assets/images/antihumidity/kit.jpg";
import galeryHome5 from "../../../assets/images/pvc/ceilings/ceilings_advantages.jpg";
import galeryHome6 from "../../../assets/images/pvc/ceilings/galery/galeryCeilings1.jpg";
import galeryHome7 from "../../../assets/images/pvc/ceilings/galery/galeryCeilings3.jpg";
import galeryHome8 from "../../../assets/images/pvc/curtains/galery/galeryCurtains1.jpg";
import galeryHome9 from "../../../assets/images/floors/floating/galery/galeryFloating1.jpg";
import galeryHome10 from "../../../assets/images/floors/vinyl/galery/galeryVinyl3.jpg";
import galeryHome11 from "../../../assets/images/floors/vinyl/galery/galeryVinyl4.jpg";
import galeryHome12 from "../../../assets/images/floors/baseboards/galery/galeryBaseboards6.jpg";

const photos = [
    {
      src: galeryHome1,
      width: 4,
      height: 3
    },
    {
      src: galeryHome2,
      width: 1,
      height: 1
    },
    {
      src: galeryHome3,
      width: 4,
      height: 3
    },
    {
      src: galeryHome4,
      width: 4,
      height: 3
    },
    {
      src: galeryHome5,
      width: 4,
      height: 3
    },
    {
      src: galeryHome6,
      width: 4,
      height: 3
    },
    {
      src: galeryHome7,
      width: 4,
      height: 3
    },
    {
      src: galeryHome8,
      width: 1,
      height: 1
    },
    {
      src: galeryHome9,
      width: 4,
      height: 3
    },
    {
      src: galeryHome10,
      width: 1,
      height: 1
    },
    {
      src: galeryHome11,
      width: 4,
      height: 3
    },
    {
      src: galeryHome12,
      width: 4,
      height: 3
    }
  ];

export default photos;