import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

//own
import "./styles.scss";
import cielorrasosPvc from "../../../../assets/images/pvc/cielorrasos.jpg";
import presupuestoColocacion from "../../../../assets/images/presupuesto_colocacion.jpg";
import ceilingsAdvantages from "../../../../assets/images/pvc/ceilings/ceilings_advantages.jpg";
import advantages from "./advantages";
import modelosCielorrasosPvc from "./models";
import accesories from "./accesories";
import Container from "./../../../../containers/Container";
import MainTitle from "./../../../../packages/app-components/MainTitle";
import ImageSection from "../../../../packages/app-components/ImageSection";
import HorizontalCard from "./../../../../packages/base-components/HorizontalCard";
import Text from "./../../../../packages/base-components/Text";
import ListProductCard from "../../../../packages/app-components/ListProductCard";
import galleryPhotos from "./galeryPhotos";
import ResponsiveGallery from "../../../../packages/base-components/ResponsiveGallery";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
  EXTRA_LARGE_CONTENT
} from "../../../../packages/utils/constants";

const Ceilings = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("ceilings", `ceilings--${size}`)}>
        <ImageSection imageSrc={cielorrasosPvc} imgAlt="Cielorrasos PVC" />
        <MainTitle>Modelos de Cielorrasos en PVC</MainTitle>
        <Text variant="body">
          {
            <div>
              <ul className="ceilings__list">
                <li>
                  Los cielorrasos de PVC son de facil instalación, libres de mantenimiento, impermeables, resistentes a innumerables agentes químicos, autoextinguibles y de agradable aspecto estético.
                </li>
                <li>
                  Son la solución ideal para
                  lograr excelentes terminaciones en cielorrasos tanto en
                  viviendas, industrias como comercios.
                </li>
                <li>
                  Sus tablas de 200 mm y de 7/10 mm de espesor se encastran de manera tal que ocultan los tornillos de sujeción, formando una superficie lisa y brillante.
                </li>
              </ul>
            </div>
          }
        </Text>
        <ListProductCard products={modelosCielorrasosPvc} sizeContent={EXTRA_LARGE_CONTENT}></ListProductCard>

        <MainTitle>Accesorios</MainTitle>
        <ListProductCard products={accesories} className="ceilings__accesories" sizeContent={EXTRA_LARGE_CONTENT}></ListProductCard>

        <MainTitle>
          ¿Querés que te presupuestemos el servicio de colocación?
        </MainTitle>
        <HorizontalCard
          description="Si necesitás que te presupuestemos la provisión y colocación de nuestros cielorrasos de PVC en manos de nuestro personal especializado, no dudes en consultarnos."
          imageAlt="Presupuesto colocación placas antihumedad"
          imageSrc={presupuestoColocacion}
          textButton="Solicitá tu presupuesto"
          linkButton="/contacto"
          navigatePage 
        />

        <MainTitle>Ventajas</MainTitle>
        <HorizontalCard
          description={
            <div>
              <ul className="advantages">
                {advantages.map((advantage, i) => {
                  return (
                    <li key={i}>
                      <FontAwesomeIcon icon={faCheckCircle} /> {advantage}
                    </li>)
                })}
              </ul>
            </div>
          }
          imageAlt="Ventajas Cielorrasos Pvc"
          imageSrc={ceilingsAdvantages}
        />
        <MainTitle>Galería</MainTitle>
        <ResponsiveGallery photos={galleryPhotos} />
      </div>
    </Container>
  );
};

export default Ceilings;
