import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//own
import Container from "./../../../containers/Container";
import MainTitle from "../../../packages/app-components/MainTitle";
import {perfilesDrywall, placasDrywall, moldurasDrywall, aislantesDrywall, masillasDrywall, accesoriosDrywall } from "./products";
import "./styles.scss";
import ListProductCard from "../../../packages/app-components/ListProductCard";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
  SMALL_CONTENT,
  LARGE_CONTENT,
  MEDIUM_CONTENT,
} from "../../../packages/utils/constants";

const Drywall = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("drywall", `drywall--${size}`)}>
        <MainTitle>Perfiles</MainTitle>
        <ListProductCard products={perfilesDrywall} sizeContent={LARGE_CONTENT}></ListProductCard>
        <MainTitle>Placas</MainTitle>
        <ListProductCard products={placasDrywall} sizeContent={LARGE_CONTENT}></ListProductCard>
        <MainTitle>Molduras</MainTitle>
        <ListProductCard products={moldurasDrywall} sizeContent={SMALL_CONTENT}></ListProductCard>
        <MainTitle>Aislantes</MainTitle>
        <ListProductCard products={aislantesDrywall} sizeContent={LARGE_CONTENT}></ListProductCard>
        <MainTitle>Masillas e impermeabilizantes</MainTitle>
        <ListProductCard products={masillasDrywall} sizeContent={MEDIUM_CONTENT}></ListProductCard>
        <MainTitle>Accesorios</MainTitle>
        <ListProductCard products={accesoriosDrywall} sizeContent={MEDIUM_CONTENT}></ListProductCard>
      </div>
    </Container>
  );
};

export default Drywall;
