import React from "react";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

//own
import "./styles.scss";
import cortinasPvc from "../../../../assets/images/pvc/curtains/cortinas.jpg";
import encajePiezascortinasPvc from "../../../../assets/images/pvc/curtains/encaje_piezas_cortina.jpg";
import modelosCortinasPvc from "./models";
import accesories from "./accesories";
import elements from "./elements";
import Container from "./../../../../containers/Container";
import MainTitle from "./../../../../packages/app-components/MainTitle";
import ImageSection from "./../../../../packages/app-components/ImageSection";
import HorizontalCard from "./../../../../packages/base-components/HorizontalCard";
import ListProductCard from "../../../../packages/app-components/ListProductCard";
import galleryPhotos from "./galeryPhotos";
import Text from "./../../../../packages/base-components/Text";
import ResponsiveGallery from "../../../../packages/base-components/ResponsiveGallery";
import {
  MATCHES_MOBILE,
  SIZE_MOBILE,
  SIZE_DESKTOP,
  MEDIUM_CONTENT,
  SMALL_CONTENT
} from "../../../../packages/utils/constants";

const Curtains = () => {
  const matchesMobile = useMediaQuery(MATCHES_MOBILE);
  const size = matchesMobile ? SIZE_MOBILE : SIZE_DESKTOP;

  return (
    <Container>
      <div className={cn("curtains", `curtains--${size}`)}>
        <ImageSection imageSrc={cortinasPvc} imgAlt="Cortinas PVC" />

        <MainTitle>Modelos de Cortinas de PVC</MainTitle>
        <Text variant="body">
          {
            <div>
              Cubren una amplia gama de requerimientos de obra, combinando
              belleza y durabilidad. Fabricadas con PVC virgen que le otorgan
              resistencia a la luz ultravioleta. Entre sus ventajas principales:
              <ul className="curtains__list">
                <li>
                <FontAwesomeIcon icon={faCheck} className="curtains__list__icon"/> Son libres de mantenimiento.
                </li>
                <li>
                <FontAwesomeIcon icon={faCheck} className="curtains__list__icon"/> Son livianas e incombustibles
                  y sus diferentes tipos de varillas permiten su adaptación a
                  los espacios más reducidos.
                </li>
                <li>
                <FontAwesomeIcon icon={faCheck} className="curtains__list__icon"/> Cumplen la función del
                  oscurecimiento del ambiente y, a su vez, hace de puente
                  térmico en situaciones de cambios de temperatura entre el
                  interior y el exterior.
                </li>
                <li>
                <FontAwesomeIcon icon={faCheck} className="curtains__list__icon"/> Protege de la lluvia y del
                  viento.
                </li>
              </ul>
            </div>
          }
        </Text>
        <ListProductCard products={modelosCortinasPvc} className="curtains__models" sizeContent={SMALL_CONTENT}></ListProductCard>

        <MainTitle>Accesorios</MainTitle>
        <ListProductCard products={accesories} className="curtains__accesories" sizeContent={MEDIUM_CONTENT}></ListProductCard>

        <MainTitle>Elementos Cortinas PVC</MainTitle>
        <HorizontalCard
          description={<ul className="curtains__elements">
            {elements.map((element) =>
            <li key={element}>{element}</li>)}
          </ul>}
          imageAlt="Elementos Cortinas PVC"
          imageSrc={encajePiezascortinasPvc}
        />
        
        <MainTitle>Galería</MainTitle>
        <ResponsiveGallery photos={galleryPhotos} />
      </div>
    </Container>
  );
};

export default Curtains;
