import offer1 from "../../../../assets/images/floors/offers/silver_oak.jpg";
import offer2 from "../../../../assets/images/floors/offers/sandalwood.jpg";
import offer3 from "../../../../assets/images/floors/offers/golden_oak.jpg";
import offer4 from "../../../../assets/images/floors/offers/bisque.jpg";
import offer5 from "../../../../assets/images/floors/offers/caramel.jpg";

const modelosLotes = [
    {
        alt: 'Piso vinílico Silver Oak Sistema Click 4mm',
        image: offer1, 
        title: 'Piso vinílico Silver Oak Sistema Click 4mm',   
        isOffer: true, 
        //price:  'm²: US$ 27,40 (Dolar Bco Nacion Vendedor) (contado)',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
        ]      
    },
    {
        alt: 'Piso vinílico Sandalwood Oak Sistema Click 4mm',
        image: offer2, 
        title: 'Piso vinílico Sandalwood Oak Sistema Click 4mm',   
        isOffer: true, 
        //price:  'm²: US$ 27,40 (Dolar Bco Nacion Vendedor) (contado)',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
        ]      
    },
    {
        alt: 'Piso vinílico Golden Oak Sistema Click 4mm',
        image: offer3, 
        title: 'Piso vinílico Golden Oak Sistema Click 4mm',   
        isOffer: true, 
        //price:  'm²: US$ 27,40 (Dolar Bco Nacion Vendedor) (contado)',
        items: [
            {
                value: 'Espesor',
                description: '4mm'
            },
        ]      
    },
    {
        alt: 'Piso vinílico Bisque Plus Sistema Click 3,5mm',
        image: offer4, 
        title: 'Piso vinílico Bisque Plus Sistema Click 3,5mm',   
        isOffer: true, 
        //price:  'm²: US$ 23,92 (Dolar Bco Nacion Vendedor) (contado)',
        items: [
            {
                value: 'Espesor',
                description: '3,5mm'
            },
        ]      
    },
    {
        alt: 'Piso vinílico Caramel Sistema Click 3,5mm',
        image: offer5, 
        title: 'Piso vinílico Caramel Sistema Click 3,5mm',   
        isOffer: true, 
        //price:  'm²: US$ 23,92 (Dolar Bco Nacion Vendedor) (contado)',
        items: [
            {
                value: 'Espesor',
                description: '3,5mm'
            },
        ]      
    },
];

export default modelosLotes;