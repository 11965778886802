/* Images Modelos Placas Antihumedad */
import sol from "../../../assets/images/antihumidity/models/sol.jpg";
//import nevada from "../../../assets/images/antihumidity/models/nevada.jpg";
import pielNaranja from "../../../assets/images/antihumidity/models/piel_naranja.jpg";
import ubeda from "../../../assets/images/antihumidity/models/ubeda.jpg";
import pizarraFina from "../../../assets/images/antihumidity/models/pizarra_fina.jpg";
import arenisca from "../../../assets/images/antihumidity/models/arenisca.jpg";
import piedraClasica from "../../../assets/images/antihumidity/models/piedra_clasica.jpg";
import laja from "../../../assets/images/antihumidity/models/laja.jpg";
import travertino from "../../../assets/images/antihumidity/models/travertino.jpg";
import lisa from "../../../assets/images/antihumidity/kit/lisa.jpg";
import arena from "../../../assets/images/antihumidity/kit/arena.jpg";
import piedra from "../../../assets/images/antihumidity/kit/piedra.jpg";

const modelosPlacasAntihumedad = [
    {
        image: sol,
        alt: 'Modelo Sol', 
        price: '$ - ', //905 (contado)
        title: 'Sol',
        items: [
            {
                value: 'Medida',
                description: '0,60 x 0,60 mts'
            }
        ]
    },
    /*{
        image: nevada,
        alt: 'Modelo Nevada', 
        price: '$420 (contado)',
        title: 'Nevada',
        items: [
            {
                value: 'Medida',
                description: '0,58 x 0,58 mts'
            }
        ]
    },*/
    {
        image: pielNaranja,
        alt: 'Modelo Piel Naranja', 
        price: '$ - ', //905 (contado)
        title: 'Piel Naranja',
        items: [
            {
                value: 'Medida',
                description: '0,60 x 0,60 mts'
            }
        ]
    },
    {
        image: ubeda,
        alt: 'Modelo Ubeda', 
        price: '$ - ', //905 (contado)
        title: 'Ubeda',
        items: [
            {
                value: 'Medida',
                description: '0,60 x 0,60 mts'
            }
        ] 
    },
    {
        image: pizarraFina,
        alt: 'Modelo Pizarra Fina', 
        price: '$ - ', //1115 (contado)
        title: 'Pizarra Fina',
        items: [
            {
                value: 'Medida',
                description: '0,60 x 0,60 mts'
            }
        ] 
    },
    {
        image: arenisca,
        alt: 'Modelo Arenisca', 
        price: '$ - ', //589 (contado)
        title: 'Arenisca',
        items: [
            {
                value: 'Medida',
                description: '0,60 x 0,30 mts'
            }
        ]
    },
    {
        image: travertino,
        alt: 'Modelo Travertino', 
        price: '$ - ', //589 (contado)
        title: 'Travertino',
        items: [
            {
                value: 'Medida',
                description: '0,52 x 0,26 mts'
            }
        ]          
    },
    {
        image: piedraClasica,
        alt: 'Modelo Piedra Clásica', 
        price: '$ - ', //589 (contado)
        title: 'Piedra Clásica',
        items: [
            {
                value: 'Medida',
                description: '0,57 x 0,28 mts'
            }
        ] 
    },
    {
        image: laja,
        alt: 'Modelo Laja', 
        price: '$ - ', //589 (contado)
        title: 'Laja',
        items: [
            {
                value: 'Medida',
                description: '0,57 x 0,28 mts'
            }
        ] 
    },    
    {
        image: lisa,
        alt: 'Modelo Lisa', 
        price: '$ - ', //589 (contado)
        title: 'Lisa',
        items: [
            {
                value: 'Medida',
                description: '0,60 x 0,30 mts'
            }
        ]         
    },
    {
        image: arena,
        alt: 'Modelo Arena', 
        price: '$ - ', //589 (contado)
        title: 'Arena',
        items: [
            {
                value: 'Medida',
                description: '0,60 x 0,30 mts'
            }
        ]        
    },
    {
        image: piedra,
        alt: 'Modelo Piedra', 
        price: '$ - ', //589 (contado)
        title: 'Piedra',
        items: [
            {
                value: 'Medida',
                description: '0,60 x 0,30 mts'
            }
        ]         
    },
];

export default modelosPlacasAntihumedad;