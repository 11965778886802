import zocalo1 from "../../../../assets/images/floors/baseboards/models/pvcJ/zocaloPvcJ1.jpg";
import zocalo2 from "../../../../assets/images/floors/baseboards/models/pvcJ/zocaloPvcJ2.jpg";
import zocalo3 from "../../../../assets/images/floors/baseboards/models/pvcJ/zocaloPvcJ3.jpg";
import zocalo4 from "../../../../assets/images/floors/baseboards/models/pvcJ/zocaloPvcJ4.jpg";


const modelosZocalosPVCTipoJ = [
    {
        alt: 'PVC J Blanco',
        image: zocalo1, 
        //price: '$356 por tira (contado)',
        title: 'PVC Blanco',
        items: [
            {
                value: 'Largo',
                description: '3000mm'
            },
        ]   
    },
    {
        alt: 'PVC J Gris',
        image: zocalo2, 
        //price: '$356 por tira (contado)',
        title: 'PVC J Gris',
        items: [
            {
                value: 'Largo',
                description: '3000mm'
            },
        ]   
    },
    {
        alt: 'PVC J Haya',
        image: zocalo3, 
        //price: '$356 por tira (contado)',
        title: 'PVC J Haya',
        items: [
            {
                value: 'Largo',
                description: '3000mm'
            },
        ]   
    },   
    {
        alt: 'PVC J Cedro',
        image: zocalo4, 
        //price: '$356 por tira (contado)',
        title: 'PVC J Cedro',
        items: [
            {
                value: 'Largo',
                description: '3000mm'
            },
        ]   
    },    
];

export default modelosZocalosPVCTipoJ;