/* Images Modelos Cortinas Pvc */
import standard from "../../../../assets/images/pvc/curtains/models/standard.jpg";
import reforzada from "../../../../assets/images/pvc/curtains/models/reforzada.jpg";
import super_reforzada from "../../../../assets/images/pvc/curtains/models/super_reforzada.jpg";
import super_reforzada_simil_madera from "../../../../assets/images/pvc/curtains/models/super_reforzada_simil_madera.jpg";
import curva from "../../../../assets/images/pvc/curtains/models/curva.jpg";
import zocalo_cortina from "../../../../assets/images/pvc/curtains/models/zocalo_cortina.jpg";

const modelosCortinasPvc = [
    {
        alt: 'L60 Standard',
        image: standard, 
        title: 'L60 Standard',
        items: []   
    },
    {
        alt: 'Curva Reforzada',
        image: curva, 
        title: 'Curva Reforzada',
        items: []   
    },
    {
        alt: 'Reforzada',
        image: reforzada, 
        title: 'Reforzada',
        items: []   
    },
    {
        alt: 'Súper Reforzada',
        image: super_reforzada, 
        title: 'Súper Reforzada',
        items: []   
    },
    {
        alt: 'Súper Reforzada Simil Madera',
        image: super_reforzada_simil_madera, 
        title: 'Simil Madera',
        items: []   
    },    
    {
        alt: 'Zócalo',
        image: zocalo_cortina, 
        title: 'Zócalo',
        items: []   
    },
];

export default modelosCortinasPvc;