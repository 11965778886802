import React from "react";
import cn from "classnames";

//own
import ProductCard from "./ProductCard";
import "./styles.scss";

const ListProductCard = (props) => {
  return (
    <div className={cn("list-product-card", props.className)}>
      {props.products.map(function (product) {
        return (
          <ProductCard
            key={product.title}
            className={cn("list-product-card__product", props.className)}
            image={product.image}
            alt={product.alt}
            items={product.items}
            price={product.price}
            isOffer={product.isOffer}
            isOfferStock={product.isOfferStock}
            title={product.title}
            refColorOcre={product.refColorOcre}
            isAvailable={product.isAvailable}
            isKit={product.isKit}
            sizeContent={props.sizeContent}            
          />
        );
      })}
    </div>
  );
};
export default ListProductCard;
