import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SquareCard from "./SquareCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// @ own
import "./styles.scss";

const ListSquareCard = ({ section, categories }) => (
  <div className="list-square-card" data-ui-section={section}>
    <Row>
      {categories &&
        categories.map(({ description, image, title, url }) => (
          <Col xs={12} sm={12} md={6} lg={6} key={url}>
            <Link
              key={title}
              className="list-square-card__link"
              to={`/${section}/${url}`}
            >
              <SquareCard
                description={description}
                imageSrc={image}
                title={title}
                className="list-square-card__square-card"
              />
            </Link>
          </Col>
        ))}
    </Row>
  </div>
);

ListSquareCard.propTypes = {
  section: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
    })
  ),
};

ListSquareCard.defaultProps = {
  section: "",
  categories: [],
};

export default ListSquareCard;
